import React, { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";

function AccountOverlay({
  showOverlay,
  setShowOverlay,
  handleSave,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  password,
  setPassword,
}) {
  const [isFirstNameEditable, setIsFirstNameEditable] = useState(false);
  const [isLastNameEditable, setIsLastNameEditable] = useState(false);
  const [isEmailEditable, setIsEmailEditable] = useState(false);
  const [isPasswordEditable, setIsPasswordEditable] = useState(false);
  const [transformButton, setTransformButton] = useState({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
  });

  const toggleEditability = (field) => {
    if (field === "firstName") setIsFirstNameEditable(!isFirstNameEditable);
    else if (field === "lastName") setIsLastNameEditable(!isLastNameEditable);
    else if (field === "email") setIsEmailEditable(!isEmailEditable);
    else if (field === "password") setIsPasswordEditable(!isPasswordEditable);

    if (
      (field === "firstName" && isFirstNameEditable) ||
      (field === "lastName" && isLastNameEditable) ||
      (field === "email" && isEmailEditable) ||
      (field === "password" && isPasswordEditable)
    ) {
      handleSave();
    }
  };

  const handleInputClick = (field) => {
    setTransformButton({
      ...transformButton,
      [field]: !transformButton[field],
    });
    setTimeout(
      () => setTransformButton({ ...transformButton, [field]: false }),
      300
    );
  };

  const getButtonClass = (field) => {
    return `ml-4 bg-white text-gray-800 border border-lightgray rounded-md px-3 py-2 text-sm transition-transform duration-200 ${
      transformButton[field] ||
      (field === "firstName" && isFirstNameEditable) ||
      (field === "lastName" && isLastNameEditable) ||
      (field === "email" && isEmailEditable) ||
      (field === "password" && isPasswordEditable)
        ? "transform scale-110"
        : ""
    }`;
  };

  const handleSubscription = async () => {
    const token = Cookies.get("token");
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/create_portal_session`,
        {},
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => (window.location = response.data.url));
  };

  return showOverlay ? (
    <div
      className="fixed inset-0 bg-[radial-gradient(circle_at_center,_rgba(0,_0,_0,_0.6)_20%,_rgba(0,_0,_0,_0.75)_100%)] flex justify-center items-center z-50"
      onClick={() => setShowOverlay(false)}
    >
      <div
        className="bg-white rounded-lg shadow-sm"
        onClick={(e) => e.stopPropagation()}
        style={{
          width: "400px",
          height: "auto", // Adjusted for dynamic content height
          boxShadow:
            "0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)",
        }}
      >
        <div className="flex flex-col space-y-1.5 p-6">
          <h3 className="text-3xl font-semibold">Manage Account</h3>
        </div>
        <div className="p-6 space-y-6 overflow-auto pt-1 pb-0">
          {/* Handling for first and last name inputs */}
          <div className="flex justify-between items-center">
            <div className="flex-1">
              <div className="text-xl font-medium leading-none mb-2">
                First Name
              </div>
              <input
                id="firstName"
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                readOnly={!isFirstNameEditable}
                onClick={() => handleInputClick("firstName")}
                className="border border-input bg-background rounded-md px-3 py-2 text-sm w-full cursor-not-allowed"
                style={{ width: "90%" }}
                placeholder="Your First Name"
              />
            </div>
            <button
              onClick={() => toggleEditability("firstName")}
              className={getButtonClass("firstName")}
              style={{ marginTop: "25px" }}
            >
              {isFirstNameEditable ? "Save" : "Edit"}
            </button>
          </div>
          <div className="flex justify-between items-center">
            <div className="flex-1">
              <div className="text-xl font-medium leading-none mb-2">
                Last Name
              </div>
              <input
                id="lastName"
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                readOnly={!isLastNameEditable}
                onClick={() => handleInputClick("lastName")}
                className="border border-input bg-background rounded-md px-3 py-2 text-sm w-full cursor-not-allowed"
                style={{ width: "90%" }}
                placeholder="Your Last Name"
              />
            </div>
            <button
              onClick={() => toggleEditability("lastName")}
              className={getButtonClass("lastName")}
              style={{ marginTop: "25px" }}
            >
              {isLastNameEditable ? "Save" : "Edit"}
            </button>
          </div>
          {/* Email and Password inputs remain unchanged */}
          <div className="flex justify-between items-center">
            <div className="flex-1">
              <div className="text-xl font-medium leading-none mb-2">Email</div>
              <input
                id="email"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                readOnly={!isEmailEditable}
                onClick={() => handleInputClick("email")}
                className="border border-input bg-background rounded-md px-3 py-2 text-sm w-full cursor-not-allowed"
                style={{ width: "90%" }}
                placeholder="Your Email"
              />
            </div>
            <button
              onClick={() => toggleEditability("email")}
              className={getButtonClass("email")}
              style={{ marginTop: "25px" }}
            >
              {isEmailEditable ? "Save" : "Edit"}
            </button>
          </div>
          <div className="flex justify-between items-center">
            <div className="flex-1">
              <div className="text-xl font-medium leading-none mb-2">
                Password
              </div>
              <input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                readOnly={!isPasswordEditable}
                onClick={() => handleInputClick("password")}
                className="border border-input bg-background rounded-md px-3 py-2 text-sm w-full cursor-not-allowed"
                style={{ width: "90%" }}
                placeholder="***********" // Updated placeholder
              />
            </div>
            <button
              onClick={() => toggleEditability("password")}
              className={getButtonClass("password")}
              style={{ marginTop: "25px" }}
            >
              {isPasswordEditable ? "Save" : "Edit"}
            </button>
          </div>
          <button
            onClick={handleSubscription}
            className="bg-white text-gray-800 border border-lightgray rounded-md px-3 py-2 text-sm"
            style={{ marginBottom: "30px" }}
          >
            Manage Subscription
          </button>
        </div>
      </div>
    </div>
  ) : null;
}

export default AccountOverlay;
