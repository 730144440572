import React, { useState, useEffect } from "react";
import FileUploadComponent from "./FileUploadComponent";
import axios from "axios";
import Cookies from "js-cookie";
import { useError } from "../../../ErrorContext";

function InputPage({
  title,
  subheading,
  placeholder,
  onInputSubmit,
  isVisible,
}) {
  const [inputValue, setInputValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [imageFiles, setImageFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [loadingText, setLoadingText] = useState("");
  const { handleError } = useError(); // Destructure handleError from the context

  const sharedFadeStyle = {
    transition: "opacity 0.5s ease",
    opacity: isVisible ? "1" : "0",
  };

  const inputStyle = {
    border: "none",
    borderBottom: isFocused ? "1px solid gray" : "0.1px solid lightgray",
    borderRadius: "0",
    outline: "none",
    fontSize: "2rem",
    textAlign: "left",
    padding: "20px 20px 20px 0",
    width: "100%",
    ...sharedFadeStyle,
  };

  const handleOkClick = () => {
    onInputSubmit(inputValue);
  };

  useEffect(() => {
    if (isUploading) {
      const loadingPhases = [
        { text: "Uploading...", delay: 2000 },
        { text: "Analyzing Assignments...", delay: 3000 },
        { text: "Transcribing Assignments...", delay: Infinity },
      ];

      let currentPhase = 0;
      setLoadingText(loadingPhases[currentPhase].text);

      const intervalId = setInterval(() => {
        currentPhase++;
        if (currentPhase < loadingPhases.length) {
          setLoadingText(loadingPhases[currentPhase].text);
        } else {
          clearInterval(intervalId); // Stop the interval when reaching the last phase
        }
      }, loadingPhases[currentPhase].delay);

      return () => clearInterval(intervalId); // Cleanup on component unmount or state change
    }
  }, [isUploading]);

  const Spinner = () => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        className="loader"
        style={{ marginRight: "20px", width: "40px", height: "40px" }}
      ></div>
      <span
        style={{
          fontSize: "1.5rem",
          color: "rgb(130, 130, 130)",
          fontWeight: "500",
        }}
      >
        {loadingText}
      </span>
    </div>
  );

  const renderInputField = () => {
    if (title.includes("Assignment?")) {
      return (
        <input
          type="text"
          style={inputStyle}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          placeholder={placeholder}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && handleOkClick()}
        />
      );
    } else if (title === "Upload Textbook Pages:") {
      return isUploading ? null : (
        <FileUploadComponent
          imageFiles={imageFiles}
          onFilesChange={(files) => setImageFiles(files)}
          onUploadClick={handleUploadAndTranscribe}
        />
      );
    } else {
      return (
        <input
          type="text"
          style={inputStyle}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          placeholder={placeholder}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && handleOkClick()}
        />
      );
    }
  };

  const handleUploadAndTranscribe = async () => {
    setIsUploading(true);
    setStatusMessage("Uploading...");
    const formData = new FormData();
    imageFiles.forEach((file, index) => {
      formData.append(`file${index + 1}`, file);
    });
    const token = Cookies.get("token");

    const config = {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/textbook_transcription`,
        formData,
        config
      );
      const { transcription } = response.data;
      onInputSubmit(transcription); // Pass the transcription text
    } catch (error) {
      console.error("Error during transcription:", error);
      handleError("Error during transcription, close and try again"); // Use handleError
      // Handle error (e.g., show error message)
    }
  };

  return (
    <>
      {!isUploading && (
        <>
          <h1
            style={{
              ...sharedFadeStyle,
              fontWeight: 600,
              fontSize: "1.9rem",
            }}
          >
            {title}
          </h1>
          {subheading && (
            <h1
              style={{
                ...sharedFadeStyle,
                fontWeight: 400,
                fontSize: "1.6rem",
                color: "rgb(180, 180, 180)",
              }}
            >
              {subheading}
            </h1>
          )}
        </>
      )}
      {renderInputField()}
      {!isUploading && title !== "Upload Textbook Pages:" && (
        <button
          style={{
            ...sharedFadeStyle,
            backgroundColor: "black",
            color: "white",
            border: "none",
            borderRadius: "5px",
            padding: "10px 20px",
            cursor: "pointer",
            marginTop: "20px",
            fontSize: "1.2rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "90px",
            height: "40px",
          }}
          onClick={handleOkClick}
        >
          OK ✓
        </button>
      )}
      {isUploading && (
        <div
          style={{
            position: "fixed", // Use fixed positioning to cover the entire viewport
            top: 0, // Pin to the top edge
            left: 0, // Pin to the left edge
            right: 0, // Pin to the right edge
            bottom: 0, // Pin to the bottom edge
            backgroundColor: "white", // Set background color to white
            display: "flex",
            alignItems: "center",
            justifyContent: "center", // Center the spinner horizontally
            zIndex: 1000, // Ensure it covers other elements
          }}
        >
          <Spinner />
        </div>
      )}
    </>
  );
}

export default InputPage;
