import React, { useEffect, useState } from "react";
import { useError } from "../../../ErrorContext";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

function QuestionWeightsPage({
  questionAndAnswers,
  isVisible,
  onWeightsSubmit,
}) {
  const scrollContainerRef = React.useRef(null);
  const { handleError } = useError(); // Destructure handleError from the context

  const [formData, setFormData] = useState(() =>
    questionAndAnswers.map((qa) => ({
      ...qa,
      points: "1", // Default points pre-populated with "1" for each question
      gradingInstructions: "",
    }))
  );

  const downloadQuestionsPDF = () => {
    const doc = new jsPDF();
    doc.text("Questions and Answers", 14, 22);
    doc.autoTable({
      head: [["Question", "Answer", "Points"]],
      body: formData.map((qa) => [qa.question, qa.answer, qa.points]),
      startY: 30,
    });
    doc.save("questions_and_answers.pdf");
  };

  useEffect(() => {
    console.log(questionAndAnswers);
  }, [questionAndAnswers]);

  const handleInputChange = (index, field, value) => {
    const updatedFormData = formData.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
    setFormData(updatedFormData);
  };

  const handleDeleteQuestion = (index) => {
    const updatedFormData = formData.filter((_, i) => i !== index);
    setFormData(updatedFormData);
  };

  const handleSubmit = () => {
    // Check if all cards have values for question, answer, and points
    const isComplete = formData.every(
      (item) =>
        item.question.trim() !== "" &&
        item.answer.trim() !== "" &&
        item.points.trim() !== ""
    );

    if (!isComplete) {
      handleError(
        "All questions, answers, and points must be filled out before submitting."
      ); // Use handleError
      return; // Exit the function early if validation fails
    }

    // Continue with the existing logic if validation passes
    const updatedFormData = formData.map((item) => ({
      ...item,
      points: item.points === "" ? "1" : item.points,
      gradingInstructions:
        item.gradingInstructions === "" ? null : item.gradingInstructions,
    }));
    onWeightsSubmit(updatedFormData);
  };

  const handleAddQuestion = () => {
    const newQuestion = {
      question: "",
      answer: "",
      points: "1",
      gradingInstructions: "",
    };
    setFormData([newQuestion, ...formData]); // Prepend the new question

    // Scroll to the top of the container
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = 0;
    }
  };

  const pointsOptions = [1, 2, 3, 4, 5];

  const buttonStyle = {
    backgroundColor: "black",
    border: "none",
    borderRadius: "5px",
    padding: "10px 20px",
    cursor: "pointer",
    marginTop: "20px",
    fontSize: "1.2rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "220px",
    height: "40px",
    fontWeight: "500",
  };

  const deleteButtonStyle = {
    position: "absolute",
    top: "20px",
    right: "20px",
    color: "black",
    border: "none",
    cursor: "pointer",
  };

  function renderAnswer(qa, index) {
    if (typeof qa.answer === "string")
      return (
        <textarea
          id={`answer-${index}`}
          value={qa.answer}
          onChange={(e) => handleInputChange(index, "answer", e.target.value)}
          className="w-full rounded-md border border-input bg-background px-3 py-2 text-sm placeholder:text-muted-foreground focus:outline-none"
          rows="4"
        />
      );

    return (
      <div className="container-fluid">
        {Object.keys(qa.answer).map((key) => (
          <div className="row">
            <div className="col">
              <textarea
                value={key}
                onChange={(e) => {
                  qa.answer[e.target.value] = qa.answer[key];
                  delete qa.answer[key];
                  key = e.target.value;
                  handleInputChange(index, "answer", qa.answer);
                }}
                className="w-full rounded-md border border-input bg-background px-3 py-2 text-sm placeholder:text-muted-foreground focus:outline-none"
                rows="2"
              />
            </div>
            <div className="col">
              <textarea
                value={
                  typeof qa.answer[key] === "string"
                    ? qa.answer[key]
                    : JSON.stringify(qa.answer[key])
                }
                onChange={(e) => {
                  qa.answer[key] = e.target.value;
                  handleInputChange(index, "answer", qa.answer);
                }}
                className="w-full rounded-md border border-input bg-background px-3 py-2 text-sm placeholder:text-muted-foreground focus:outline-none"
                rows="2"
              />
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div>
      <h1 className="mt-3" style={{ fontWeight: 600, fontSize: "1.9rem" }}>
        Review Questions
      </h1>
      <div
        ref={scrollContainerRef}
        style={{
          maxHeight: "60vh", // Set a max height for the scrollable area
          display: isVisible ? "block" : "none",
          overflow: "auto",
        }}
      >
        {formData.map((qa, index) => (
          <div
            key={index}
            className="relative rounded-lg border bg-card text-card-foreground shadow-sm my-4"
            data-v0-t="card"
            style={{ marginRight: "10px" }}
          >
            <button
              style={{ ...deleteButtonStyle }}
              onClick={() => handleDeleteQuestion(index)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
              </svg>
            </button>
            <div className="p-6">
              <div className="flex flex-col space-y-4">
                <div className="space-y-2">
                  <label
                    className="text-sm font-medium leading-none"
                    htmlFor={`question-${index}`}
                  >
                    Question
                  </label>
                  <textarea
                    id={`question-${index}`}
                    type="text"
                    value={qa.question}
                    onChange={(e) =>
                      handleInputChange(index, "question", e.target.value)
                    }
                    className="w-full rounded-md border border-input bg-background px-3 py-2 text-sm placeholder:text-muted-foreground focus:outline-none"
                    rows="4"
                  />
                </div>
                <div className="space-y-2">
                  <label className="text-sm font-medium leading-none">
                    Answer
                  </label>
                  {renderAnswer(qa, index)}
                </div>
                <div className="flex space-x-4">
                  <div className="space-y-2 flex-1">
                    <label
                      className="text-sm font-medium leading-none"
                      htmlFor={`points-${index}`}
                    >
                      Points
                    </label>
                    <input
                      type="number"
                      id={`points-${index}`}
                      className="w-full rounded-md border border-input bg-background px-3 py-2 text-sm placeholder:text-muted-foreground focus:outline-none"
                      value={qa.points} // Reflects the default or updated points value
                      min="1"
                      step="1"
                      onChange={(e) =>
                        handleInputChange(index, "points", e.target.value)
                      }
                    />
                  </div>
                  <div className="space-y-2 flex-1">
                    <label
                      className="text-sm font-medium leading-none"
                      htmlFor={`grading-${index}`}
                    >
                      Grading Instructions
                    </label>
                    <input
                      type="text"
                      id={`grading-${index}`}
                      className="w-full rounded-md border border-input bg-background px-3 py-2 text-sm placeholder:text-muted-foreground focus:outline-none"
                      placeholder="Optional..."
                      value={qa.gradingInstructions}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "gradingInstructions",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div
        style={{
          display: isVisible ? "flex" : "none",
          gap: "10px",
          marginTop: "20px",
        }}
      >
        <button
          style={{ ...buttonStyle, color: "white" }}
          onClick={handleSubmit}
        >
          Submit Assignment
        </button>
        <button
          style={{
            ...buttonStyle,
            backgroundColor: "white",
            border: ".5px solid lightgray",
          }} // Adjust the color as needed
          onClick={handleAddQuestion}
        >
          Add Question
        </button>
        <button
          style={{
            ...buttonStyle,
            backgroundColor: "white",
            border: ".5px solid lightgray",
            color: "black",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "40px", // Set the button width to 40px
            height: "40px", // Adjust the height if necessary to maintain the design
            padding: "0px",
          }}
          onClick={downloadQuestionsPDF}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#3d3d3d"
            class="bi bi-cloud-download-fill"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M8 0a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 4.095 0 5.555 0 7.318 0 9.366 1.708 11 3.781 11H7.5V5.5a.5.5 0 0 1 1 0V11h4.188C14.502 11 16 9.57 16 7.773c0-1.636-1.242-2.969-2.834-3.194C12.923 1.999 10.69 0 8 0m-.354 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V11h-1v3.293l-2.146-2.147a.5.5 0 0 0-.708.708z"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}

export default QuestionWeightsPage;
