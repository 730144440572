import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Webcam from "react-webcam";
import Cookies from "js-cookie";
import axios from "axios"; // Ensure axios is imported

const Scanner = () => {
  const headerHeight = 15; // Header height in pixels
  const buttonDivHeight = 60;
  const buttonHeight = 0.05 * window.screen.height;
  const [camera, setCamera] = useState("environment");
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [studentName, setStudentName] = useState("");
  const inputRef = useRef(null); // Ref for the input element

  const [overlayStyle, setOverlayStyle] = useState({
    opacity: 0,
    visibility: "hidden",
  });
  const { url } = useParams();
  // State for controlling the camera button's size
  const [isButtonEnlarged, setIsButtonEnlarged] = useState(false);

  // Reference to the Webcam component
  const webcamRef = useRef(null);

  // State for storing the captured images (now an array)
  const [capturedImages, setCapturedImages] = useState([]);
  // State for controlling the Review Images overlay's visibility
  const [isReviewOverlayVisible, setIsReviewOverlayVisible] = useState(false);

  // Refs for the assignment card and image container
  const assignmentCardRef = useRef(null);
  const imageContainerRef = useRef(null);

  const useViewportHeight = () => {
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

    useEffect(() => {
      const handleWindowResize = () => {
        setViewportHeight(window.innerHeight);
      };

      window.addEventListener("resize", handleWindowResize);

      // Call the handler right away so state gets updated with initial window size
      handleWindowResize();

      return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return viewportHeight;
  };
  const viewportHeight = useViewportHeight();
  const handleInputChange = (e) => {
    setStudentName(e.target.value);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus(); // Ensure focus on component mount
    }
  }, []);

  // Add a function to handle form submission
  const handleSubmit = async () => {
    // Get the access code from the URL parameter
    const accessCode = url;
    const token = Cookies.get("token");

    // Prepare the form data
    const formData = new FormData();
    formData.append("access_code", accessCode);
    formData.append("student_name", studentName); // Include the student's name

    // Assuming capturedImages is an array of base64 image strings
    capturedImages.forEach((image, index) => {
      // Convert base64 string to a file object
      const blob = dataURLtoBlob(image);
      formData.append(
        `image${index + 1}`,
        new File([blob], `image${index + 1}.jpg`, { type: "image/jpeg" })
      );
    });

    // Set the request URL
    const requestURL = `${process.env.REACT_APP_API_URL}/add_images_to_scanner_upload`;

    try {
      // Make the POST request using axios
      const response = await axios.post(requestURL, formData, {
        headers: {
          // 'Content-Type': 'multipart/form-data' is not needed; axios sets it automatically when you pass FormData
          Authorization: token, // Replace with actual token
        },
      });

      // Handle the response
      console.log("Success:", response.data);
      // Clear the captured images and close the review overlay
      setCapturedImages([]);
      setIsReviewOverlayVisible(false);
      // Handle success (e.g., show a success message)
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.statusText : error.message
      );
      // Handle request error (e.g., show an error message)
    }
  };

  // Function to convert base64 to Blob object
  const dataURLtoBlob = (dataURL) => {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    const byteNumbers = new Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      byteNumbers[i] = byteString.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeString });
  };

  useEffect(() => {
    // Function to check if the click is outside the assignment card and images
    const handleClickOutside = (event) => {
      if (
        assignmentCardRef.current &&
        !assignmentCardRef.current.contains(event.target) &&
        imageContainerRef.current &&
        !imageContainerRef.current.contains(event.target)
      ) {
        closeReviewOverlay(); // Close the overlay if the click is outside
      }
    };

    // Add the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [assignmentCardRef, imageContainerRef]);

  // Function to open the Review Images overlay
  const openReviewOverlay = () => {
    setIsReviewOverlayVisible(true);
  };

  // Function to close the Review Images overlay
  const closeReviewOverlay = () => {
    setIsReviewOverlayVisible(false);
  };

  // Function to capture an image from the webcam
  const captureImage = () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setCapturedImages((prevImages) => [...prevImages, imageSrc]);
    } else {
      console.error("Webcam is not ready");
    }
  };

  // Function to open the Review Images overlay with a check for images
  const handleUploadClick = () => {
    if (capturedImages.length > 0) {
      openReviewOverlay();
    } else {
    }
  };

  // Function to handle camera button click
  const handleCameraButtonClick = () => {
    captureImage();
    setIsButtonEnlarged(true);
    setTimeout(() => setIsButtonEnlarged(false), 150); // Reset button size, shadow, and opacity after 100ms
  };

  const cameraButtonStyle = {
    height: "80px", // Set height to 60px
    width: "80px", // Set width to 60px
    borderRadius: "100px", // Make it a circle
    backgroundColor: "rgba(255, 255, 255, 0.6)", // White with 60% transparency
    border: "1px solid lightgray", // 1px light gray border
    position: "fixed", // Position relative to the viewport
    left: "50%", // Center horizontally
    transform: "translateX(-50%)", // Adjust for the button's own width
    bottom: "calc(6vh + 30px)", // 15px from the bottom of the viewport
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  // Adjusted camera button style
  const adjustedCameraButtonStyle = {
    ...cameraButtonStyle,
    boxShadow: isButtonEnlarged ? "0 0 10px rgba(255, 255, 255, 0.9)" : "none", // White shadow effect
    backgroundColor: isButtonEnlarged
      ? "rgba(255, 255, 255, 0.8)"
      : "rgba(255, 255, 255, 0.6)", // Change opacity on click
    transform: isButtonEnlarged
      ? "translate(-50%, -50%) scale(1.1)"
      : "translate(-50%, -50%)",
    transition: "transform 100ms, box-shadow 100ms, background-color 100ms",
    position: "fixed",
    left: "50%", // Center horizontally
    bottom: "calc(6vh)", // Position from bottom
    transformOrigin: "center center", // Ensure scaling happens from the center
  };

  // Function to open the overlay
  const openOverlay = () => {
    setIsOverlayVisible(true); // Set visible first
    setOverlayStyle({
      opacity: 1,
      visibility: "visible",
    });
  };

  // Function to close the overlay with transition
  const closeOverlay = () => {
    setOverlayStyle({
      opacity: 0,
      visibility: "visible",
    });
    setTimeout(() => {
      setIsOverlayVisible(false);
      setOverlayStyle({
        opacity: 0,
        visibility: "hidden",
      });
    }, 2000); // Wait for the transition to complete
  };

  // Overlay component
  const Overlay = () => (
    <div
      style={{
        ...overlayStyle,
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "white",
        zIndex: 1001,
        transition: "opacity 2s, visibility 2s",
      }}
    >
      <p style={{ textAlign: "center" }}>Overlay Content</p>
      <button onClick={closeOverlay}>Close Overlay</button>
    </div>
  );

  const ReviewImagesOverlay = () => (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background:
          "radial-gradient(circle at center, rgba(0, 0, 0, 0.7) 20%, rgba(0, 0, 0, 0.9) 100%)",
        zIndex: 1002,
        display: "flex",
        justifyContent: "space-around", // Adjusts items to be spaced evenly
        alignItems: "center", // Centers vertically
        paddingRight: "10px",
      }}
    >
      {/* Image Container */}
      <div
        ref={imageContainerRef}
        style={{
          width: "40%",
          overflowY: "scroll",
          maxHeight: "90%",
          padding: "10px",
        }}
      >
        {capturedImages.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Captured image ${index + 1}`}
            style={{
              width: "100%", // Adjusted to use full width of container
              marginBottom: "15px",
              borderRadius: "10px",
            }}
          />
        ))}
      </div>

      {/* Review Card Container */}
      <div
        ref={assignmentCardRef}
        className="rounded-lg border bg-card text-card-foreground shadow-sm"
        style={{
          backgroundColor: "white",
          padding: "10px",
          width: "200px",
          height: "260px",
        }}
      >
        <div class="flex flex-col space-y-1.5 p-2 pb-0">
          <h3 class="text-lg font-semibold whitespace-nowrap leading-none tracking-tight">
            Assignment Review
          </h3>
          <p class="text-xs text-muted-foreground">
            If the student name isn't in the images, provide it below
          </p>
        </div>
        <div class="p-2 space-y-2">
          <div class="flex items-center justify-left">
            <span
              class="text-base font-semibold"
              style={{ marginRight: "8px" }}
            >
              Images Uploaded:
            </span>
            <span class="text-base font-bold">{capturedImages.length}</span>
          </div>
          <div class="space-y-2">
            <label
              class="text-xs font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              for="name"
            >
              Student's Name
            </label>
            <input
              ref={inputRef}
              value={studentName}
              onChange={handleInputChange}
              class="mt-0 flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
              id="name"
              placeholder="(Optional)"
            />
          </div>
        </div>
        <div class="flex items-center p-2 pt-0">
          <button
            class="inline-flex items-center justify-center whitespace-nowrap rounded-lg border border-gray-300 opacity-50 mb-2.5 flex justify-center items-center text-xs h-[30px] w-full"
            style={{
              backgroundColor: "#e6e6e6",
              border: "1px solid lightGray",
            }}
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );

  // Function to toggle between front and back camera
  const toggleCamera = () => {
    setCamera((prevCamera) => (prevCamera === "user" ? "environment" : "user"));
  };

  const [webcamDimensions, setWebcamDimensions] = useState({
    height: window.screen.height - buttonDivHeight - 30,
    width: window.screen.width - 20,
  });

  useEffect(() => {
    const handleResize = () => {
      setWebcamDimensions({
        height:
          window.screen.height - headerHeight - 0.02 * window.screen.height,
        width: window.screen.width,
      });
      console.log(webcamDimensions);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const headerStyle = {
    display: "flex",
    alignItems: "center",
    height: "40px",
    backgroundColor: "white",
    zIndex: 1000,
  };

  const aspectRatio = webcamDimensions.width / webcamDimensions.height;
  const videoConstraints = {
    width: { ideal: 1920 },
    height: { ideal: 1080 },

    facingMode: camera,
  };

  const buttonsDivStyle = {
    position: "fixed",
    left: 0,
    right: 0,
    bottom: 0,
    height: buttonDivHeight,
    width: "100vw",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "space-between", // Changed to space-between
    alignItems: "center",
    padding: "15px",
    paddingTop: "30px",
    paddingLeft: "15px",
    paddingRight: "15px",
    marginBottom: "5px",
  };

  const leftGroupStyle = {
    display: "flex", // Add this to group the first two buttons together
  };

  const buttonStyle = {
    height: buttonHeight, // Fixed height for better control
    width: "40px", // Fixed width
    borderRadius: "10px",
    backgroundColor: "#e6e6e6",
    border: "0.5px solid lightGray",
    opacity: 0.5,
    marginBottom: "10px",
    marginRight: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const webcamStyle = {
    borderRadius: "10px",
    marginLeft: "10px",
    marginRight: "10px",
    marginTop: "10px",
    position: "relative", // Set relative positioning for webcam container
  };

  // New button style
  const uploadButtonStyle = {
    height: buttonHeight, // 90% of the parent height plus 15px
    width: "145px", // Divide parent width by 3 for each button
    borderRadius: "10px",
    backgroundColor: capturedImages.length > 0 ? "#e6e6e6" : "transparent",

    border: "0.5px solid lightGray",
    opacity: 0.5,
    marginBottom: "10px",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: ".7rem",
    lineHeight: "12px", // Set the height between lines of text to 5px
  };

  return (
    <div style={{ height: "calc(100vh - 20px)", width: "100vw" }}>
      <Webcam
        ref={webcamRef}
        videoConstraints={videoConstraints}
        style={{
          borderRadius: "15px",
          marginLeft: "10px",
          marginRight: "10px",
          marginTop: "10px",
          objectFit: "cover",
          height: "calc(97vh)",
          width: "calc(100% - 20px)",
        }}
        screenshotFormat="image/jpeg"
      />
      <div style={webcamStyle}>
        <button
          style={adjustedCameraButtonStyle}
          onClick={handleCameraButtonClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="currentColor"
            class="bi bi-camera"
            viewBox="0 0 16 16"
          >
            <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4z" />
            <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5m0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7M3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0" />
          </svg>
        </button>
      </div>
      <div style={buttonsDivStyle}>
        <div style={leftGroupStyle}>
          <button style={buttonStyle} onClick={toggleCamera}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-phone-flip"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M11 1H5a1 1 0 0 0-1 1v6a.5.5 0 0 1-1 0V2a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v6a.5.5 0 0 1-1 0V2a1 1 0 0 0-1-1m1 13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-2a.5.5 0 0 0-1 0v2a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-2a.5.5 0 0 0-1 0zM1.713 7.954a.5.5 0 1 0-.419-.908c-.347.16-.654.348-.882.57C.184 7.842 0 8.139 0 8.5c0 .546.408.94.823 1.201.44.278 1.043.51 1.745.696C3.978 10.773 5.898 11 8 11q.148 0 .294-.002l-1.148 1.148a.5.5 0 0 0 .708.708l2-2a.5.5 0 0 0 0-.708l-2-2a.5.5 0 1 0-.708.708l1.145 1.144L8 10c-2.04 0-3.87-.221-5.174-.569-.656-.175-1.151-.374-1.47-.575C1.012 8.639 1 8.506 1 8.5c0-.003 0-.059.112-.17.115-.112.31-.242.6-.376Zm12.993-.908a.5.5 0 0 0-.419.908c.292.134.486.264.6.377.113.11.113.166.113.169s0 .065-.13.187c-.132.122-.352.26-.677.4-.645.28-1.596.523-2.763.687a.5.5 0 0 0 .14.99c1.212-.17 2.26-.43 3.02-.758.38-.164.713-.357.96-.587.246-.229.45-.537.45-.919 0-.362-.184-.66-.412-.883s-.535-.411-.882-.571M7.5 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1z"
              />
            </svg>
          </button>
          <button style={buttonStyle} onClick={openOverlay}>
            <svg
              onClick={openOverlay}
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="currentColor"
              class="bi bi-info-lg"
              viewBox="0 0 16 16"
            >
              <path d="m9.708 6.075-3.024.379-.108.502.595.108c.387.093.464.232.38.619l-.975 4.577c-.255 1.183.14 1.74 1.067 1.74.72 0 1.554-.332 1.933-.789l.116-.549c-.263.232-.65.325-.905.325-.363 0-.494-.255-.402-.704zm.091-2.755a1.32 1.32 0 1 1-2.64 0 1.32 1.32 0 0 1 2.64 0" />
            </svg>
          </button>
        </div>
        <button style={uploadButtonStyle} onClick={handleUploadClick}>
          Upload Assignment: <br /> {capturedImages.length} Images
        </button>
      </div>
      {isReviewOverlayVisible && <ReviewImagesOverlay />}
      {isOverlayVisible && <Overlay />}
    </div>
  );
};

export default Scanner;
