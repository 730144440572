import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const ResetPasswordComponent = ({ resetToken }) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [successMessage, setSuccessMessage] = useState(""); // State for success message
  const [errorMessage, setErrorMessage] = useState(""); // State for error message

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccessMessage(""); // Reset success message
    setErrorMessage(""); // Reset error message

    if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords do not match!"); // Update error message
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/reset_password`,
        {
          reset_token: resetToken,
          new_password: newPassword,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      // Adjust the success message part
      if (response.status === 200) {
        setSuccessMessage(
          <>
            Password reset successfully. You can now{" "}
            <Link
              to="/login"
              style={{ color: "inherit", textDecoration: "underline" }}
            >
              login
            </Link>{" "}
            with your new password.
          </>
        ); // Update success message to include Link component
      }
    } catch (error) {
      // Extract error message from server response or use a default message
      const message =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "An error occurred while resetting your password.";

      setErrorMessage(message); // Update error message with server response or default
      console.error(error);
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className="rounded-lg border bg-card text-card-foreground shadow-lg"
        style={{
          maxWidth: "300px",
          width: "100%",
          margin: "auto",
          boxShadow:
            "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
        }}
        data-v0-t="card"
      >
        <div className="flex flex-col p-4 pb-0 space-y-1">
          <h3 className="whitespace-nowrap tracking-tight text-xl font-bold">
            Reset password
          </h3>
          <p className="text-sm text-muted-foreground">
            Enter your password, and confirm it, then head to the login page!
          </p>
          {successMessage && (
            <p className="text-sm text-green-600 pt-3">{successMessage}</p>
          )}{" "}
          {/* Display success message */}
          {errorMessage && (
            <p className="text-sm text-red-600 pt-3">{errorMessage}</p>
          )}{" "}
          {/* Display error message */}
        </div>
        <form onSubmit={handleSubmit} className="p-4">
          <div className="space-y-3">
            <div className="space-y-2">
              <label className="text-sm font-medium" htmlFor="password">
                New Password
              </label>
              <input
                className="flex h-8 w-full rounded-md border border-input bg-background px-2 py-1 text-sm placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-black focus:border-black"
                id="password"
                required=""
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <div className="space-y-2">
              <label className="text-sm font-medium" htmlFor="confirm-password">
                Confirm Password
              </label>
              <input
                className="flex h-8 w-full rounded-md border border-input bg-background px-2 py-1 text-sm placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-black focus:border-black"
                id="confirm-password"
                required=""
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <button
              className="flex items-center justify-center w-full h-8 px-4 py-1 rounded-md text-white bg-black hover:bg-black/90 focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 mt-3"
              type="submit"
            >
              Reset Password
            </button>
          </div>
        </form>
        <div className="text-center text-sm p-4 pt-0" style={{ color: "gray" }}>
          Remember your password?{" "}
          <a className="underline" style={{ color: "gray" }} href="#">
            Login
          </a>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordComponent;
