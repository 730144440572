import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate, Link } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import ReCAPTCHA from "react-google-recaptcha"; // Import ReCAPTCHA component
import logo from "../osmos-logo.png";
import "../index.css";
import { useError } from "../ErrorContext";

function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null); // new state for error message
  const [showErrorGlow, setShowErrorGlow] = useState(false);
  const recaptchaRef = useRef(null); // useRef hook to reference the reCAPTCHA instance
  const [captchaValue, setCaptchaValue] = useState(null); // State to store captcha response
  const { handleError } = useError(); // Destructure handleError from the context

  const navigate = useNavigate(); // Get the navigate function

  useEffect(() => {
    if (Cookies.get("token")) navigate("/dashboard");
  }, [navigate]);

  // useEffect hook to listen for changes to captchaValue and print it
  useEffect(() => {
    if (captchaValue) {
      console.log("ReCAPTCHA token set:", captchaValue);
    }
  }, [captchaValue]);

  const isFormFilled = email !== "" && password !== "";

  const checkCircleIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-check-circle-fill"
      viewBox="0 0 16 16"
    >
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
    </svg>
  );

  // Function to initiate Google login process
  const initiateGoogleLogin = async (googleData, token) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/google_login`,
        {
          id_token: googleData.credential,
          captcha: token, // Use the token directly
        }
      );

      Cookies.set("token", response.data.token);

      if (response.data.token.startsWith("Teacher")) {
        navigate("/dashboard");
      }
    } catch (error) {
      console.error("Error processing Google login", error);
      handleError("Failed to process Google login");
    }
  };

  const handleGoogleLogin = (googleData) => {
    const recaptchaInstance = recaptchaRef.current;
    if (recaptchaInstance) {
      recaptchaInstance
        .execute()
        .then((token) => {
          // Set the reCAPTCHA token in the state and print it
          setCaptchaValue(token);
          initiateGoogleLogin(googleData, token); // Adjust the function to accept the token
        })
        .catch((error) => {
          console.error("ReCAPTCHA verification failed", error);
          handleError("ReCAPTCHA verification failed. Please try again.");
        });
    } else {
      console.error("ReCAPTCHA not loaded");
      handleError(
        "ReCAPTCHA not loaded. Please refresh the page and try again."
      );
    }
  };

  const handleLoginClick = () => {
    if (!email || !password) {
      setError("Please fill in both email and password.");
      return;
    }

    setError(null);

    const recaptchaInstance = recaptchaRef.current;
    if (recaptchaInstance) {
      recaptchaInstance
        .execute()
        .then((token) => {
          // Instead of setting the token in state, pass it directly to handleSubmit
          handleSubmit(token); // Pass token directly
        })
        .catch((error) => {
          setError(
            "ReCAPTCHA not loaded. Please refresh the page and try again."
          );
        });
    } else {
      setError("ReCAPTCHA not loaded. Please refresh the page and try again.");
    }
  };

  const handleSubmit = async (token) => {
    // Accept token as a parameter
    setError(null);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/login`,
        {
          email,
          password,
          captcha: token, // Use the token directly instead of from state
        }
      );

      Cookies.set("token", response.data.token);

      if (response.data.token.startsWith("Student")) {
        navigate("/student_home");
      } else if (response.data.token.startsWith("Teacher")) {
        navigate("/dashboard");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setError("Invalid email or password.");
      } else {
        setError("An unexpected error occurred. Please try again later.");
        console.error("Login error:", error);
      }
    }
  };

  return (
    <div className="row" style={{ display: "flex", height: "100vh" }}>
      <div
        className="col-lg-4"
        style={{
          background:
            "radial-gradient(circle, rgba(246,246,246,1) 0%, rgba(224,224,224,1) 60%)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",

          justifyContent: "center",
          padding: "20px",
          textAlign: "left",
        }}
      >
        <div
          style={{
            color: "#000000",
            fontSize: "1.6rem",
            fontWeight: "500",
            textAlign: "left",
            width: "400px",
          }}
        >
          Never Grade a Worksheet Again!
        </div>
        <ul
          style={{
            listStyleType: "none",
            padding: "10px 0 0",
            margin: 0,
            width: "400px",
          }}
        >
          {[
            "Reduce time spent grading by 40%",
            "No more weekends lost to mountains of worksheets",
            "Free yourself to give more to your students",
          ].map((item) => (
            <li
              key={item}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "5px",
                marginRight: "40px",
                width: "400px",
              }}
            >
              {checkCircleIcon}
              <span style={{ marginLeft: "10px", fontSize: "1rem" }}>
                {item}
              </span>
            </li>
          ))}
        </ul>
      </div>

      <div
        className="col-lg-8"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "350px",
            height: "450px",
            padding: "28px",
            border: "0.5px solid #d3d3d3",
            borderRadius: "5px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={logo}
            alt="Osmos Logo"
            style={{ width: "200px", marginBottom: "15px" }}
          />
          <h2
            style={{
              fontSize: "1.9rem",
              marginBottom: "3px",
              paddingTop: "10px",
              fontWeight: "600",
            }}
          >
            Sign in
          </h2>
          <p style={{ fontSize: ".9rem", marginBottom: "15px" }}>
            to continue to Osmos
          </p>
          {error && <div className="alert alert-danger">{error}</div>}
          <input
            type="text"
            className={`form-control ${showErrorGlow ? "errorGlow" : ""}`}
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{
              margin: "5px 0",
              height: "35px",
              width: "270px",
              fontSize: "0.8rem",
              borderRadius: "3px",
            }}
          />
          <input
            type="password"
            className={`form-control ${showErrorGlow ? "errorGlow" : ""}`}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleLoginClick();
            }}
            style={{
              margin: "5px 0",
              height: "35px",
              width: "270px",
              fontSize: "0.8rem",
              borderRadius: "3px",
            }}
          />
          <div style={{ marginBottom: "5px" }}>
            <Link
              to="/forgot-password"
              style={{
                color: "gray",
                textDecoration: "underline",
                fontSize: ".8rem",
                marginRight: "20px",
              }}
            >
              Forgot Password?
            </Link>
            <Link
              to="/register"
              style={{
                color: "gray",
                textDecoration: "underline",
                fontSize: ".8rem",
              }}
            >
              New User?
            </Link>
          </div>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6Lf4nX4pAAAAAJ1AFhaxUCITyY5mFbjH1BSQ1jhT"
            size="invisible"
            badge="invisible" // Place reCAPTCHA badge inline with other elements or as per your design needs
          />
          <button
            className={`btn btn-primary ${showErrorGlow ? "errorGlow" : ""}`}
            onClick={handleLoginClick}
            style={{
              margin: "5px 0",
              width: "270px",
              backgroundColor: isFormFilled ? "#0d6efd" : "#add8e6",
              border: "none",
              fontSize: "0.8rem",
              borderRadius: "3px",
              height: "35px",
            }}
          >
            Login
          </button>

          <hr style={{ width: "270px", marginTop: "10px" }} />
          <GoogleLogin
            onSuccess={handleGoogleLogin}
            onError={() => console.log("Login Failed")}
            style={{ width: "270px" }} // Adjusted width to 270px
          />
        </div>
      </div>
    </div>
  );
}

export default LoginForm;
