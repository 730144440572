// FileUploadComponent.js
import React from "react";

function FileUploadComponent({
  imageFiles = [],
  onFilesChange,
  onUploadClick,
}) {
  const handleFileChange = (event) => {
    onFilesChange([...event.target.files]);
  };

  return (
    <>
      <input
        id="fileInput"
        type="file"
        accept="image/*, application/pdf"
        style={{ display: "none" }}
        onChange={handleFileChange}
        multiple
      />
      <label
        htmlFor="fileInput"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#f0f0f0",
          padding: "15px",
          margin: "20px 0",
          height: "150px",
          border: "1px dashed #ccc",
          cursor: "pointer",
          color: "rgb(170, 170, 170)",
          fontWeight: "500",
          fontSize: "2.4rem",
        }}
      >
        {imageFiles.length > 0
          ? `${imageFiles.length} file(s) selected`
          : "Upload a blank assignment"}
      </label>
      <button
        style={{
          backgroundColor: "black",
          color: "white",
          border: "none",
          borderRadius: "5px",
          padding: "10px 20px",
          cursor: "pointer",
          marginTop: "20px",
          fontSize: "1rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "120px",
          height: "40px",
          opacity: imageFiles.length > 0 ? "1" : "0.3",
          cursor: imageFiles.length > 0 ? "pointer" : "default",
        }}
        onClick={onUploadClick}
        disabled={imageFiles.length === 0}
      >
        Upload ✓
      </button>
    </>
  );
}

export default FileUploadComponent;
