import React from "react";
import { QRCode } from "react-qrcode-logo";
import axios from "axios";
import Cookies from "js-cookie";

function ScannerCard({ url, onScanFinished, onManualUpload, assignmentID }) {
  const handleScanButtonClick = () => {
    console.log("button clicked");

    // Retrieve the token from cookies
    const token = Cookies.get("token"); // Use js-cookie to get the token

    // Define the API endpoint URL
    const apiURL = `${process.env.REACT_APP_API_URL}/enqueue_submission_with_access_code`;

    // Prepare the data to be sent in the request
    const formData = new FormData();
    formData.append("access_code", url); // Assuming `url` is the access code
    formData.append("assignment_id", assignmentID);

    // Make the POST request using Axios with the Authorization header
    axios
      .post(apiURL, formData, {
        headers: {
          Authorization: token, // Include the token in the Authorization header
        },
      })
      .then((response) => {
        console.log("Submission enqueued successfully:", response.data);
        onScanFinished(); // Invoke the onScanFinished callback after successful API call
      })
      .catch((error) => {
        console.error(
          "Error enqueuing submission:",
          error.response?.data || error.message
        );
      });
  };

  const handleManualUpload = () => {
    console.log("Manual upload initiated");
    onManualUpload(); // Invoke the onManualUpload callback passed as a prop
  };

  // Create the complete URL with the query parameter
  const qrCodeValue = `https://app.osmoslearn.com/scanner/${encodeURIComponent(
    url
  )}`;

  return (
    <div
      className="rounded-lg border bg-card text-card-foreground shadow-sm w-full max-w-md mx-auto"
      style={{
        backgroundColor: "white",
        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
      }}
    >
      <div
        className="flex flex-col space-y-1.5 p-8"
        style={{ paddingBottom: "5px" }}
      >
        <h3 className="text-2xl font-semibold whitespace-nowrap leading-none tracking-tight">
          Scan your paper assignments
        </h3>
        <p className="text-sm text-muted-foreground">
          Use your phone to scan the QR code and open the assignment scanner.
        </p>
      </div>
      <div className="p-6 flex flex-col items-center space-y-4">
        <QRCode value={qrCodeValue} size={200} eyeRadius={5} />
        <a
          style={{ fontSize: ".6rem", color: "gray", marginTop: "-2px" }}
          href={qrCodeValue}
        >
          {qrCodeValue.split("://").slice(1)}
        </a>

        <button
          style={{
            backgroundColor: "white",
            width: "300px",
            height: "40px",
            borderRadius: "8px",
            border: ".5px solid lightgray",
            transition: "background-color 0.3s, transform 0.3s",
            marginTop: "30px",
          }}
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = "#d9d9d9";
            e.target.style.transform = "scale(1.05)";
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = "white";
            e.target.style.transform = "scale(1)";
          }}
          onClick={handleScanButtonClick}
        >
          Done Scanning
        </button>

        <p
          className="text-sm text-gray-500 underline cursor-pointer"
          style={{ marginBottom: "10px" }}
          onClick={handleManualUpload}
        >
          Upload Manually
        </p>
      </div>
    </div>
  );
}

export default ScannerCard;
