import React from "react";

const HelpCard = ({ instructions, closeOverlay }) => {
  const handleCardClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      onClick={closeOverlay} // Triggers the closeOverlay function when the overlay is clicked
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background:
          "radial-gradient(circle at center, rgba(0, 0, 0, 0.6) 20%, rgba(0, 0, 0, 0.75) 100%)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)",
      }}
    >
      <div
        className="rounded-lg border bg-card text-card-foreground shadow-sm w-full max-w-md mx-auto"
        data-v0-t="card"
        style={{ backgroundColor: "white" }}
        onClick={handleCardClick}
      >
        <div className="flex flex-col space-y-1.5 p-6 pb-0">
          <div className="flex items-center space-x-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="h-6 w-6 text-gray-500 dark:text-gray-400"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
            </svg>
            <h3 className="text-2xl font-semibold whitespace-nowrap leading-none tracking-tight">
              Need Help?
            </h3>
          </div>
        </div>
        <div className="p-6">
          {instructions && (
            <div className="mb-4 text-gray-500 dark:text-gray-400">
              {instructions}
            </div>
          )}
          <p className="text-gray-500 dark:text-gray-500">
            Thanks for being an early user of Osmos, we're glad to have you on
            board! Here's my personal phone number and email, feel if you have
            any questions about Osmos, want to report a bug, or just chat!
            <br />
            -ryan ❤️
          </p>
          <p className="mt-4 text-lg font-semibold text-gray-900 dark:text-gray-700">
            +1 (910) 242-4445
          </p>
          <p className="mt-2 text-lg font-semibold text-gray-900 dark:text-gray-700">
            ryan@osmoslearn.com
          </p>
        </div>
      </div>
    </div>
  );
};

export default HelpCard;
