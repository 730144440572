import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import AlertComponent from "./AlertComponent";
import { useError } from "../ErrorContext"; // Import useError hook

function TeacherClasses() {
  const [classes, setClasses] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Added isLoading state
  const [isCreating, setIsCreating] = useState(false);
  const [newClass, setNewClass] = useState({ title: "", description: "" });
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const { handleError } = useError(); // Destructure handleError from the context

  useEffect(() => {
    let timeout;
    if (showAlert) {
      timeout = setTimeout(() => {
        setShowAlert(false);
      }, 5000); // Set timeout for 10 seconds
    }

    return () => {
      clearTimeout(timeout); // Clear timeout if component unmounts
    };
  }, [showAlert]);

  const handleCreateClassClick = () => {
    setIsCreating(true);
  };

  const handleInputChange = (e) => {
    setNewClass({ ...newClass, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const fetchClasses = async () => {
      setIsLoading(true); // Start loading
      try {
        const token = Cookies.get("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/get_classes`,
          {
            headers: {
              Authorization: token,
            },
          }
        );

        setClasses(response.data);
      } catch (error) {
        handleError("Failed to fetch classes"); // Use handleError
      } finally {
        setIsLoading(false); // End loading
      }
    };

    fetchClasses();
  }, []);

  // Function to handle button click
  const handleLaunch = (classId) => {
    navigate(`/class/${classId}`);
  };

  const handleSubmitNewClass = async () => {
    const payload = {
      name: newClass.title,
      description: newClass.description,
    };

    if (!newClass.description.trim()) {
      setShowAlert(true);
      return;
    }
    setShowAlert(false); // Reset alert if description is valid

    try {
      const token = Cookies.get("token");
      let response = await axios.post(
        `${process.env.REACT_APP_API_URL}/create_class`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      if (response.status === 200) {
        // Directly fetching classes from the endpoint using axios
        response = await axios.get(
          `${process.env.REACT_APP_API_URL}/get_classes`,
          {
            headers: {
              Authorization: token,
            },
          }
        );

        if (response.status === 200) {
          const classesData = response.data;
          setClasses(classesData); // Update the state with the fetched classes
          setNewClass({ title: "", description: "" }); // Clear the newClass state
          setIsCreating(false); // Reset the creating state
        }
      }
    } catch (error) {
      console.error("Failed to create class or fetch classes", error);
      handleError("Failed to fetch classes"); // Use handleError
    }
  };

  const handleDeleteClass = async (classId) => {
    if (window.confirm("Are you sure you want to delete this class?")) {
      try {
        const token = Cookies.get("token");
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/delete_class`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            data: { class_id: classId },
          }
        );

        if (response.status === 200) {
          setClasses(classes.filter((class_) => class_.id !== classId));
        }
      } catch (error) {
        console.error("Failed to delete class", error);
        handleError("Failed to delete classes"); // Use handleError
      }
    }
  };

  return (
    <div
      className="d-flex flex-wrap justify-content-left"
      style={{ padding: "0", marginLeft: "-10px" }}
    >
      {isCreating ? (
        <div
          className="card m-2"
          style={{ minWidth: "30%", minHeight: "25vh" }}
        >
          <div className="card-body">
            <input
              type="text"
              name="title"
              value={newClass.title}
              onChange={handleInputChange}
              placeholder="Class Name..."
              style={{
                border: "none",
                outline: "none",
                fontSize: "2.2rem", // This will style the input text, not the placeholder
                fontWeight: "600", // Same as above, applies to text not placeholder
                marginBottom: "0.5rem", // Assuming you want some space between the fields
                padding: "0", // Removes padding
                paddingLeft: "5px",
                marginLeft: "-5px",
                // Other styles as needed
              }}
              className="form-control"
            />

            <input
              name="description"
              value={newClass.description}
              onChange={handleInputChange}
              placeholder="Class Description..."
              style={{
                border: "none",
                outline: "none",
                fontSize: "1.1rem", // Styles the text inside the textarea
                marginBottom: "0.5rem", // Spacing
                padding: "0", // Removes padding
                paddingLeft: "5px",
                // Other styles as needed
              }}
              className="form-control"
            />

            <button
              onClick={handleSubmitNewClass}
              className="btn btn-primary"
              style={{
                color: "rgb(54, 98, 227)",
                fontWeight: "500",
                display: "inline-flex",
                alignItems: "center",
                position: "absolute",
                bottom: "20px",
                left: "16px",
                padding: "0",
                background: "none", // Remove button background
                border: "none", // Remove button border
                cursor: "pointer",
                marginLeft: "-3px",
                marginTop: "-3px",
                padding: "3px",
              }}
            >
              Create Class
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                viewBox="0 0 16 16"
                style={{ marginLeft: "4px", marginTop: "1px" }}
              >
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
              </svg>
            </button>
          </div>
          <button
            onClick={() => {
              setIsCreating(false); // Set isCreating to false
              setNewClass({ title: "", description: "" }); // Clear newClass
            }}
            style={{
              position: "absolute",
              top: "15px",
              right: "15px",
              backgroundColor: "white",
              border: "none",
              cursor: "pointer",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="28"
              fill="currentColor"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
            </svg>
          </button>
        </div>
      ) : !isLoading && classes.length === 0 ? (
        <div
          className="rounded-lg border text-card-foreground shadow-sm w-full max-w-md aspect-[16/9]"
          data-v0-t="card"
          style={{
            minWidth: "30%",
            minHeight: "25vh",
            backgroundColor: "#fff",
            display: "flex", // Ensure the outer div is a flex container
            alignItems: "center", // Center vertically
            justifyContent: "center", // Center horizontally
          }}
        >
          <div className="flex flex-col items-center justify-center p-6 space-y-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="h-12 w-12 text-gray-500 dark:text-gray-400"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="12" x2="12" y1="8" y2="12"></line>
              <line x1="12" x2="12.01" y1="16" y2="16"></line>
            </svg>
            <h2 className="text-2xl font-semibold text-gray-600 dark:text-gray-600">
              No Classes Yet
            </h2>
            <p
              className="text-gray-500 dark:text-gray-500"
              style={{ textAlign: "center", width: "320px" }}
            >
              You haven't created any classes yet. Click the button below to
              create your first class.
            </p>
            <button
              onClick={handleCreateClassClick}
              className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground h-10 text-gray-500 dark:text-gray-500"
              style={{ width: "150px" }}
            >
              Create Class
            </button>
          </div>
        </div>
      ) : (
        <button
          onClick={handleCreateClassClick}
          style={{
            position: "absolute",
            bottom: "20px",
            right: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "80px",
            height: "80px",
            fontSize: "100px",
            borderRadius: "40px"
          }}
        >
          +
        </button>
      )}
      {showAlert && (
        <AlertComponent
          type="error" // or "warning" based on the context
          message="A class description is required." // Customizable message
        />
      )}
      {classes.map((class_, index) => (
        <div
          key={index}
          className="card m-2"
          style={{
            borderRadius: "5px",
            backgroundColor: "#fff",
            textAlign: "left",
            width: "300px",
            height: "250px",
            cursor: "pointer",
            overflow: "auto"
          }}
          onClick={() => handleLaunch(class_.id)}
        >
          <div
            className="card-body d-flex flex-column"
            style={{ padding: "20px", height: "100%" }}
          >
            <div className="flex-grow-1">
              <h5
                className="card-title"
                style={{ color: "#000", fontWeight: "600", fontSize: "2.2rem" }}
              >
                {class_.name}
              </h5>
              <p
                className="card-text"
                style={{ color: "#000", fontSize: "1.1rem" }}
              >
                {class_.description || "No description available."}
              </p>
            </div>

            <button
              className="btn btn-launch" // Use the new class for the button
              style={{
                color: "rgb(54, 98, 227)",
                fontWeight: "500",
                display: "inline-flex",
                alignItems: "center",
                padding: "0",
                background: "none", // Remove button background
                border: "none", // Remove button border
                cursor: "pointer", // Change cursor to pointer on hover
              }}
            >
              Launch Class
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-arrow-right ml-2"
                viewBox="0 0 16 16"
                style={{ strokeWidth: "2" }}
              >
                <path
                  fillRule="evenodd"
                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                />
              </svg>
            </button>

            <button
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteClass(class_.id);
              }}
              style={{
                position: "absolute",
                top: "15px",
                right: "15px",
                backgroundColor: "white",
                border: "none",
                cursor: "pointer",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="28"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path
                  d="M4.646 4.646a.5.5
0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"
                />
              </svg>
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default TeacherClasses;
