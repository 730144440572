import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import CreateAiGradeAssignment from "./sub_components/CreateAiGradeAssignment";

function CreateAssignmentCard({ classId, onAssingmentCreated }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showAiGradeOverlay, setShowAiGradeOverlay] = useState(false);
  const [overlayVisible, setOverlayVisible] = useState(false); // State to handle overlay visibility for transition

  useEffect(() => {
    if (showAiGradeOverlay) {
      // When the overlay is shown, we wait a tick to trigger the CSS transition for fade-in.
      const timeoutId = setTimeout(() => {
        setOverlayVisible(true);
      }, 10); // A short delay ensures the transition is applied for fade-in.
      return () => clearTimeout(timeoutId);
    }
  }, [showAiGradeOverlay]);

  const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "white",
    zIndex: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "opacity 0.2s ease-in-out", // Ensure smooth transition for both fade-in and fade-out
    opacity: overlayVisible ? 1 : 0,
    pointerEvents: overlayVisible ? "auto" : "none",
  };

  const handleNewAssignmentCreated = () => {
    console.log("createAssignmentCard reached");
    closeOverlay();
    onAssingmentCreated();
  };

  const handleAiGradeClick = () => {
    setShowAiGradeOverlay(true); // This will trigger the useEffect hook
  };

  const handleCloseOverlay = () => {
    closeOverlay();
  };

  const closeOverlay = () => {
    setOverlayVisible(false); // Trigger fade-out
    setTimeout(() => {
      setShowAiGradeOverlay(false);
    }, 500); // Wait for the fade-out transition before hiding the overlay
  };

  return (
    <div>
      <Button
        onClick={handleAiGradeClick}
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "80px",
          height: "80px",
          fontSize: "50px",
          borderRadius: "40px",
        }}
      >
        +
      </Button>

      {showAiGradeOverlay && (
        <div style={overlayStyle}>
          <CreateAiGradeAssignment
            onClose={handleCloseOverlay}
            classId={classId}
            onNewAssignmentCreated={handleNewAssignmentCreated}
          />
        </div>
      )}
    </div>
  );
}

export default CreateAssignmentCard;
