import React, { useState } from "react";
import FileUploadComponent from "./FileUploadComponent";
import Cookies from "js-cookie";

// Reusing the buttonStyle from the original component
const buttonStyle = {
  padding: "20px",
  borderRadius: "10px",
  border: "0.5px solid gray",
  backgroundColor: "white",
  cursor: "pointer",
  fontSize: "16px",
  margin: "10px",
  minWidth: "150px",
  transition: "background-color 0.3s, color 0.3s, transform 0.3s",
};

// Adapted from pdfSectionStyle and imageSectionStyle for a single section
const sectionStyle = {
  height: "300px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "50px", // Adjusted padding to apply on all sides equally
  border: "1px solid #d1d5db",
  margin: "50px auto", // Center the section and apply margin on top for spacing
  borderRadius: "8px",
};

function AutoGenerateSelector({ onOptionSelected, onDataReceived }) {
  const [showUpload, setShowUpload] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [loadingText, setLoadingText] = useState("");

  const handleOptionClick = (option) => {
    if (option === "uploadExisting") {
      setShowUpload(true);
    } else {
      // Handle other options as needed
      onOptionSelected(option);
    }
  };

  const handleFilesChange = (files) => {
    setSelectedFiles(files);
  };

  const handleUploadClick = async () => {
    setIsUploading(true);
    setLoadingText("Uploading...");

    const formData = new FormData();
    selectedFiles.forEach((file, index) => {
      formData.append(`file${index + 1}`, file);
    });

    const token = Cookies.get("token");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/transcribe_answer_key`,
        {
          method: "POST",
          headers: {
            Authorization: token,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setIsUploading(false); // Reset the uploading state
      setLoadingText(""); // Clear loading text

      // Call the onDataReceived callback with the response data
      if (onDataReceived) {
        onDataReceived(data);
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      setIsUploading(false); // Reset the uploading state
      setLoadingText(""); // Clear loading text
      // Optionally handle the error, e.g., by setting an error message state and displaying it in the UI
    }
  };

  const Spinner = () => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row", // Change this line
        gap: "20px", // Optionally add some space between the spinner and the text
      }}
    >
      <div className="loader" style={{ width: "40px", height: "40px" }}></div>
      <span
        style={{
          fontSize: "2rem",
          color: "rgb(130, 130, 130)",
          fontWeight: "500",
        }}
      >
        {loadingText}
      </span>
    </div>
  );

  return (
    <div
      className="flex justify-center items-center" // Centering the entire component
      style={{ height: "100vh", flexDirection: "column" }} // Adjust height as needed
    >
      <div
        className="flex justify-center items-center"
        style={{ height: "100vh", flexDirection: "column" }}
      >
        {isUploading ? (
          <Spinner />
        ) : !showUpload ? (
          <div style={sectionStyle}>
            <h2 className="text-3xl font-bold mb-4">Choose Your Option</h2>
            <p
              className="text-gray-500 dark:text-gray-400 mb-8"
              style={{ textAlign: "center" }}
            >
              Select whether to have Osmos generate an answer key or upload an
              existing key.
            </p>
            <div>
              <button
                style={buttonStyle}
                onClick={() => handleOptionClick("autoGenerate")}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = "#d9d9d9";
                  e.target.style.transform = "scale(1.05)";
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "white";
                  e.target.style.transform = "scale(1)";
                }}
              >
                Auto Generate
              </button>
              <button
                style={buttonStyle}
                onClick={() => handleOptionClick("uploadExisting")}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = "#d9d9d9";
                  e.target.style.transform = "scale(1.05)";
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "white";
                  e.target.style.transform = "scale(1)";
                }}
              >
                Upload Existing
              </button>
            </div>
          </div>
        ) : (
          <FileUploadComponent
            imageFiles={selectedFiles}
            onFilesChange={handleFilesChange}
            onUploadClick={handleUploadClick}
          />
        )}
      </div>
    </div>
  );
}

export default AutoGenerateSelector;
