import React from "react";
import { useError } from "./ErrorContext"; // Adjust the path as needed
import AlertComponent from "./components/AlertComponent"; // Ensure the path is correct

const GlobalErrorHandler = () => {
  const { error } = useError();

  if (!error) return null;

  return <AlertComponent type="error" message={error} />;
};

export default GlobalErrorHandler; // Correctly export GlobalErrorHandler as a default export
