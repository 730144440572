import Cookies from "js-cookie";
import axios from "axios";
import { useError } from "../../../ErrorContext";
import React, { useState } from "react";

const StudentDetailsCard = ({
  key,
  studentData,
  assignmentId,
  onDeleteStudent,
  onClose,
  onDataChange,
}) => {
  const { handleError } = useError(); // Destructure handleError from the context
  const [studentDataState, setStudentDataState] = useState(studentData);

  console.log(studentData);

  // Adjusted styling for the override buttons with updated padding
  const overrideButtonStyle = {
    backgroundColor: "white", // Background color
    color: "#4a4a4a", // Text color
    border: "2px solid lightgray", // Border color
    borderRadius: "15px", // Rounded corners
    padding: "5px", // Adjusted padding to ensure 5px from the text to the button edges
    fontSize: "0.8rem", // Font size
    display: "flex", // Flex display to center content
    alignItems: "center", // Center content vertically
    justifyContent: "center", // Center content horizontally
    width: "70%", // Full width
    cursor: "pointer", // Pointer cursor on hover
    transition: "background-color 0.3s ease", // Smooth transition for background color
    fontSize: "0.8rem", // Font size of the text within the button
    transition:
      "background-color .3s ease, color .3s ease, transform 0.3s ease, border-color .3s ease",
  };

  const indicatorStyle = {
    marginBottom: "15px", // Space above the question text
    padding: "5px 10px", // Padding inside the indicator
    borderRadius: "15px", // Rounded corners
    textAlign: "center", // Center the text inside the indicator
    fontSize: "0.8rem", // Font size of the indicator text, corrected duplicate
    width: "140px",
    height: "30px",
    display: "flex", // Added for additional centering support
    alignItems: "center", // Vertically centers the text inside the div
    justifyContent: "center", // Horizontally centers the text inside the div (redundant with textAlign but added for demonstration)
    color: "#4a4a4a",
  };

  const handleDeleteStudent = () => {
    const token = Cookies.get("token");

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/delete_submission`,
        {
          assignment_id: assignmentId,
          submission_id: studentDataState.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      )
      .then((response) => {
        const data = response.data;
        if (
          data.message === "Student deleted successfully from the assignment"
        ) {
          onDeleteStudent(studentDataState.name); // Passing the student's name to the callback
        } else {
          console.error("Failed to delete the student");
          handleError("Failed to delete the student"); // Use handleError
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  let nameTimer = 0;

  function updateName(e) {
    nameTimer++;
    let current = nameTimer;

    const token = Cookies.get("token");

    setTimeout(() => {
      if (nameTimer === current) {
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/update_student_name`,
            {
              submission_id: studentDataState.id,
              new_student_name: e.target.value,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: token,
              },
            }
          )
          .then(() => {
            onDataChange(); // Call onDataChange callback function here
          })
          .catch((error) => {
            console.error("Error:", error);
          });
        nameTimer = 0;
      }
    }, 500);
  }

  function updateQuestion(originalIndex, correct) {
    const token = Cookies.get("token");

    // Use the original index to identify the question for the backend
    // Assuming the backend has been adapted to accept an original index or a unique ID
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/update_question_correctness`,
        {
          submission_id: studentDataState.id,
          question_index: originalIndex, // This should match the backend's expected identifier
          correct: correct,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      )
      .then((response) => {
        console.log("Question correctness updated successfully", response);

        // Update the gradePercentage from the response
        const updatedGradePercentage = `${response.data.gradePercentage}%`;

        // Map through the original questions array to update the correct status and explanation
        const updatedQuestions = studentDataState.questions.map(
          (question, index) => {
            if (index === originalIndex) {
              // Use the original index to find the question to update
              return {
                ...question,
                correct: correct, // Update the correct value
                explanation: correct
                  ? ""
                  : "Teacher override: Marked as incorrect.",
              };
            }
            return question;
          }
        );

        // Update the state with the modified questions array and the new grade percentage
        setStudentDataState((prevState) => ({
          ...prevState,
          questions: updatedQuestions,
          gradePercentage: updatedGradePercentage,
        }));

        if (onDataChange) {
          onDataChange();
        }
      })
      .catch((error) => {
        console.error("Error updating question correctness:", error);
        handleError("Failed to update question correctness");
      });
  }

  if (!studentDataState) {
    return <div className="text-center p-6">Select a student</div>;
  }

  const flagText = {
    "Name not found": "Error: Name not found",
    "Low Transcription Quality": "Warning: Low Transcription Quality",
  };

  // Annotate each question with its original index before sorting
  const annotatedQuestions = studentDataState.questions.map(
    (question, index) => ({
      ...question,
      originalIndex: index, // Add the original index to each question object
    })
  );

  // Sort the annotated questions by correctness, with incorrect questions first
  const sortedQuestions = annotatedQuestions.sort((a, b) => {
    return a.correct === b.correct ? 0 : a.correct ? 1 : -1;
  });

  return (
    <div
      className="bg-white shadow-md rounded-lg"
      style={{
        height: "100%",
        width: "100%",
        border: "0.5px solid #e0e0e0",
      }}
    >
      <div className="p-6 pb-0 flex justify-between items-center">
        <input
          className="text-3xl font-semibold leading-none w-100 mb-2"
          type="text"
          defaultValue={studentDataState.name}
          onChange={updateName}
        />
        <div className="flex items-center">
          <button
            className="rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground flex items-center justify-center"
            onClick={handleDeleteStudent}
            style={{
              width: "120px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "40px",
            }}
          >
            <p style={{ margin: 0, fontSize: ".8rem" }}>Delete Student</p>
          </button>
          <button
            className="rounded-md text-sm font-medium ml-2 transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground h-10 w-10 flex items-center justify-center"
            onClick={onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-x-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </button>
        </div>
      </div>
      {studentDataState.flag && (
        <p className="text-red-500 mb-2" style={{ marginLeft: "25px" }}>
          {flagText[studentDataState.flag]}
        </p>
      )}
      <p className="text-gray-500 mb-2" style={{ marginLeft: "25px" }}>
        Grade: {studentDataState.gradePercentage}
      </p>
      <div
        style={{
          overflowY: "auto",
          maxHeight: "calc(100% - 100px)",
          padding: "10px",
        }}
      >
        {sortedQuestions.map((question, i) => (
          <div
            key={i}
            className="rounded relative" // Add 'relative' here to make it the positioning context
            style={{
              backgroundColor: "white",
              padding: "25px",
              margin: "10px",
              marginTop: i === 0 ? "0" : "15px", // Apply margin top conditionally
              border: ".5px solid lightgray",
            }}
          >
            {/* Conditional Rendering for Question Indicator */}
            {!question.correct ? (
              <div
                style={{
                  ...indicatorStyle,
                  backgroundColor: "#ffbaba",
                  border: "2px solid #ff8c8c",
                  cursor: "default",
                }}
              >
                Incorrect Question
              </div>
            ) : (
              <div
                style={{
                  ...indicatorStyle,
                  backgroundColor: "#abffb1",
                  border: "2px solid #6eff78",
                  cursor: "default",
                }}
              >
                Correct Question
              </div>
            )}

            <div className="row">
              <div className="col-md-9 col-7">
                <p style={{ fontSize: "1.2rem", fontWeight: "600" }}>
                  {question.question}
                </p>
              </div>
              <div className="col-md-3 col-5">
                {question.correct ? (
                  <button
                    style={{
                      ...overrideButtonStyle,
                      position: "absolute", // Absolute positioning within the card
                      top: "20px", // 20px from the top of the container
                      right: "20px", // 20px from the right of the container
                      width: "150px",
                    }}
                    onClick={() =>
                      updateQuestion(question.originalIndex, false)
                    }
                    onMouseOver={(e) => {
                      e.currentTarget.style.backgroundColor = "#ffbaba";
                      e.currentTarget.style.transform = "scale(1.05)";
                      e.currentTarget.style.borderColor = "#ff8c8c";
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.backgroundColor = "initial";
                      e.currentTarget.style.transform = "scale(1)";
                      e.currentTarget.style.borderColor = "lightgray";
                    }}
                  >
                    Override as Incorrect
                  </button>
                ) : (
                  <button
                    style={{
                      ...overrideButtonStyle,
                      position: "absolute",
                      top: "25px",
                      right: "20px",
                      width: "150px",
                    }}
                    onClick={() => updateQuestion(question.originalIndex, true)}
                    onMouseOver={(e) => {
                      e.currentTarget.style.backgroundColor = "#abffb1";
                      e.currentTarget.style.transform = "scale(1.05)";
                      e.currentTarget.style.borderColor = "#6eff78";
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.backgroundColor = "initial";
                      e.currentTarget.style.transform = "scale(1)";
                      e.currentTarget.style.borderColor = "lightgray";
                    }}
                  >
                    Override as Correct
                  </button>
                )}
              </div>
            </div>
            <p style={{ marginBottom: "10px", marginTop: "5px" }}>
              <span style={{ fontWeight: "600" }}>Student's Answer:</span>
              <span style={{ fontWeight: "400" }}> {question.answer}</span>
            </p>
            {!question.correct && (
              <p style={{ marginBottom: "5px" }}>
                <span style={{ fontWeight: "600" }}>Reasoning:</span>
                <span style={{ fontWeight: "400" }}>
                  {" "}
                  {question.explanation}
                </span>
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default StudentDetailsCard;
