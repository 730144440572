import React from "react";

export default function ChooseFormatComponent({ onFormatChosen }) {
  const handleChoice = (choice) => {
    onFormatChosen(choice);
  };

  const buttonStyle = {
    padding: "20px",
    borderRadius: "10px",
    border: "0.5px solid gray",
    backgroundColor: "white",
    cursor: "pointer",
    fontSize: "16px",
    margin: "10px",
    minWidth: "150px",
    transition: "background-color 0.3s, color 0.3s",
  };

  const pdfSectionStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingRight: "50px", // Padding only on the right
    borderRight: "2px solid #d1d5db",
  };

  const imageSectionStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "50px", // Padding only on the left
  };

  return (
    <div className="row w-100" style={{ marginTop: "50px" }}>
      <div
        className="col justify-center"
        style={{ ...pdfSectionStyle, height: "250px" }}
      >
        <div>
          <h2 className="text-3xl font-bold mb-4">Upload Files</h2>
        </div>
        <div>
          <p
            className="text-gray-500 dark:text-gray-400 mb-8"
            style={{ textAlign: "center" }}
          >
            Upload PDF files, from your device
          </p>
        </div>
        <div>
          <button style={buttonStyle} onClick={() => handleChoice("PDF")}>
            Upload Files
          </button>
        </div>
      </div>
      <div className="col justify-center" style={imageSectionStyle}>
        <div>
          <h2 className="text-3xl font-bold mb-4">Osmos Scanner</h2>
        </div>
        <div>
          <p
            className="text-gray-500 dark:text-gray-400 mb-8"
            style={{ textAlign: "center" }}
          >
            Upload images with Osmos Scanner.
          </p>
        </div>
        <div>
          <button style={buttonStyle} onClick={() => handleChoice("Image")}>
            Osmos Scanner
          </button>
        </div>
      </div>
    </div>
  );
}
