import React, { useState } from "react";

const StudentsTable = ({ students, onStudentClick, onAddAssignment }) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);

  // New function to handle 'Add More Assignments' button click
  const handleAddAssignmentClick = () => {
    console.log("handleAddAssignmentClick called");

    onAddAssignment();
  };

  const handleStudentClick = (student) => {
    if (onStudentClick) {
      onStudentClick(student);
    }
  };

  const handleImageClick = (student) => {
    // Assuming student object contains an array of imageUrls
    setImageUrls(student.imageUrls || [student.imageUrl]); // Fallback to single imageUrl if array is not available
    setShowOverlay(true);
  };

  const toggleOverlay = () => {
    setShowOverlay(!showOverlay);
  };

  const downloadCSV = () => {
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += "Name,Grade\n"; // CSV Column Headers

    students.forEach((student) => {
      const row = `${student.name},${student.gradePercentage}`;
      csvContent += row + "\n";
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "students_grades.csv");
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file named "students_grades.csv"
  };

  return showOverlay ? (
    <div
      onClick={toggleOverlay} // Close overlay when clicking on the background
      style={{
        width: "100%",
        maxHeight: "100%",
        display: "flex",
        justifyContent: "center", // Center children horizontally
        padding: "20px",
        cursor: "pointer",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "30px",
        }}
      >
        {imageUrls.map((url, index) => (
          <img
            key={index}
            src={url}
            alt={`Student Work ${index + 1}`}
            style={{
              height: "auto",
              borderRadius: "7px",
            }}
          />
        ))}
      </div>
    </div>
  ) : (
    <div
      className="bg-white shadow-md rounded-lg"
      style={{
        height: "100%",
        width: "100%",
        maxHeight: "calc(100vh - 64px)",
        border: "0.5px solid #e0e0e0",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <div className="p-6">
        <div className="flex justify-between items-center mb-3">
          <h3 className="text-2xl font-semibold leading-none">Students</h3>
          <div className="flex gap-2">
            <button
              onClick={handleAddAssignmentClick}
              className="rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground h-11 w-auto px-3 flex items-center justify-center"
              style={{
                transition:
                  "background-color .3s ease, color .3s ease, transform 0.3s ease, border-color .3s ease",
                cursor: "pointer",
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = "#f5f5f5";
                e.currentTarget.style.transform = "scale(1.05)";
                e.currentTarget.style.borderColor = "black";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = "initial";
                e.currentTarget.style.transform = "scale(1)";
                e.currentTarget.style.borderColor = "initial";
              }}
            >
              Add Assignments
            </button>
            <button
              onClick={downloadCSV}
              className="rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground h-11 w-auto px-3 flex items-center justify-center"
              style={{
                transition:
                  "background-color .3s ease, color .3s ease, transform 0.3s ease, border-color .3s ease",
                cursor: "pointer",
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = "#f5f5f5";
                e.currentTarget.style.transform = "scale(1.05)";
                e.currentTarget.style.borderColor = "black";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = "initial";
                e.currentTarget.style.transform = "scale(1)";
                e.currentTarget.style.borderColor = "initial";
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383" />
                <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className="px-6 pb-6">
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="font-bold text-sm text-gray-500 border-b border-grey-light text-center">
                  Image
                </th>
                <th
                  className="font-bold text-sm text-gray-500 border-b border-grey-light "
                  style={{ paddingLeft: "20px" }}
                >
                  Name
                </th>
                <th className="font-bold text-sm text-gray-500 border-b border-grey-light text-center">
                  Grade
                </th>
                <th className="font-bold text-sm text-gray-500 border-b border-grey-light text-center">
                  Review
                </th>
              </tr>
            </thead>
            <tbody>
              {students.map((student, index) => (
                <tr key={index} style={{ height: "70px" }}>
                  <td
                    className="border-b border-grey-light text-center"
                    style={{ width: "110px" }}
                  >
                    <img
                      onClick={() => handleImageClick(student)}
                      className="h-10 w-10 rounded-full mx-auto"
                      src={student.imageUrls[0]}
                      alt="Student Assignment"
                      style={{
                        cursor: "pointer", // Set cursor to pointer
                        borderRadius: "5px",
                        height: "50px",
                        border: "0.5px solid #e0e0e0",
                        transition: " transform 0.3s ease", // Added border-color to the transition
                      }} // Added border style to images
                      onMouseOver={(e) => {
                        e.currentTarget.style.transform = "scale(1.05)"; // Scale up on hover
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.style.transform = "scale(1)"; // Scale up on hover
                      }}
                    />
                  </td>
                  <td
                    className=" border-b border-grey-light"
                    style={{ fontWeight: "600", paddingLeft: "20px" }}
                  >
                    {student.name}
                  </td>
                  <td
                    className=" border-b border-grey-light text-center"
                    style={{ width: "100px", fontWeight: "500" }}
                  >
                    {student.gradePercentage}
                  </td>
                  <td
                    className=" border-b border-grey-light text-center"
                    style={{ width: "230px" }}
                  >
                    <button
                      onClick={() => handleStudentClick(student)}
                      className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground h-10 w-10 mx-auto"
                      style={{
                        transition:
                          "background-color .3s ease, color .3s ease, transform 0.3s ease, border-color .3s ease", // Added border-color to the transition
                      }}
                      onMouseOver={(e) => {
                        e.currentTarget.style.backgroundColor = "#f5f5f5"; // Light gray background on hover
                        e.currentTarget.style.transform = "scale(1.1)"; // Scale up on hover
                        e.currentTarget.style.borderColor = "black"; // Darker border on hover
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.style.backgroundColor = "initial"; // Revert to default background color
                        e.currentTarget.style.transform = "scale(1)"; // Scale up on hover
                        e.currentTarget.style.borderColor = "initial"; // Revert to default border color
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-eye"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                      </svg>
                      <span className="sr-only">View</span>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default StudentsTable;
