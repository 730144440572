import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useError } from "../ErrorContext"; // Import useError hook

function ClassTitleCard({ classId, setClassName }) {
  // Receive the update function
  const [titleData, setTitleData] = useState({});
  const { handleError } = useError(); // Destructure handleError from the context

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = Cookies.get("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/get_title_card_data/${classId}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        setTitleData(response.data);
      } catch (error) {
        console.error("Error fetching data: ", error);
        handleError("Failed to get title card data"); // Use handleError
      }
    };

    fetchData();
  }, [classId, setClassName]); // Add setClassName as a dependency

  return (
    <div
      className="class-title-card"
      style={{
        borderRadius: "25px",
        height: "30vh",
        margin: "15px auto",
        display: "block",
        position: "relative",
        boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.3)",
      }}
    >
      <img
        src={titleData.image_url}
        alt="Class"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: "15px",
        }}
      />
      <div
        style={{
          position: "absolute",
          bottom: "20px",
          left: "20px",
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          borderRadius: "15px",
          paddingTop: "5px",
          paddingBottom: "0px",
          paddingLeft: "10px",
          paddingRight: "10px",
        }}
      >
        <p
          style={{
            color: "white",
            marginBottom: "-5px",
            fontSize: "2.6rem",
            fontWeight: 500,
          }}
        >
          {titleData.name}
        </p>
        <p style={{ color: "white", fontSize: "1.5rem", marginBottom: "10px" }}>
          {titleData.description}
        </p>
        {titleData.photographer_username && (
          <p style={{ color: "white", fontSize: ".7rem" }}>
            Photo by{" "}
            <a
              href={`https://unsplash.com/@${titleData.photographer_username}?utm_source=Osmos&utm_medium=referral`}
              style={{ color: "#FFF" }}
            >
              {titleData.photographer_name}
            </a>{" "}
            on{" "}
            <a
              href="https://unsplash.com/?utm_source=your_app_name&utm_medium=referral"
              style={{ color: "#FFF" }}
            >
              Unsplash
            </a>
          </p>
        )}
      </div>
    </div>
  );
}

export default ClassTitleCard;
