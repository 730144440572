import React from "react";
import { QRCode } from "react-qrcode-logo";

function AssignmentScanner({ url, onScanFinished, onManualUpload }) {
  const handleScanButtonClick = () => {
    console.log("button clicked");
    onScanFinished(); // Invoke the onScanFinished callback
  };

  const handleManualUpload = () => {
    console.log("Manual upload initiated");
    onManualUpload(); // Invoke the onManualUpload callback passed as a prop
  };

  // Create the complete URL with the query parameter
  const qrCodeValue = `https://app.osmoslearn.com/scanner/${encodeURIComponent(
    url
  )}`;

  return (
    <div className="fixed top-0 right-0 bottom-0 left-0 flex items-center justify-center bg-white">
      <div className="rounded-lg border bg-card text-card-foreground shadow-sm w-full max-w-md mx-auto">
        <div
          className="flex flex-col space-y-1.5 p-8"
          style={{ paddingBottom: "5px" }}
        >
          <h3 className="text-2xl font-semibold whitespace-nowrap leading-none tracking-tight">
            Scan a Blank Assignment
          </h3>
          <p className="text-sm text-muted-foreground">
            Use your phone to scan the QR code and open the assignment scanner.
          </p>
        </div>
        <div className="p-6 flex flex-col items-center space-y-4">
          <QRCode
            value={qrCodeValue} // Use the dynamically generated QR code value
            size={200} // Set QR code size
            eyeRadius={5}
          />
          <p style={{ fontSize: ".6rem", color: "gray", marginTop: "-2px" }}>
            {qrCodeValue.substring(8)}
          </p>

          <button
            style={{
              backgroundColor: "white",
              width: "300px",
              height: "40px",
              borderRadius: "8px",
              border: ".5px solid lightgray",
              transition: "background-color 0.3s, transform 0.3s",
              marginTop: "30px",
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = "#d9d9d9";
              e.target.style.transform = "scale(1.05)";
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = "white";
              e.target.style.transform = "scale(1)";
            }}
            onClick={handleScanButtonClick}
          >
            Done Scanning
          </button>

          <p
            className="text-sm text-gray-500 underline cursor-pointer"
            style={{ marginBottom: "10px" }}
            onClick={handleManualUpload} // Add onClick event handler for manual upload
          >
            Upload Manually
          </p>
        </div>
      </div>
    </div>
  );
}

export default AssignmentScanner;
