import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import TeacherAutoGradeAssignment from "./sub_components/TeacherAutoGradeAssignment";
import { useError } from "../ErrorContext"; // Import useError hook

function TeacherAssignmentCard({
  classId,
  newAssignmentFlag,
  refreshAssignments,
}) {
  // Add newAssignmentFlag to destructuring
  const [assignments, setAssignments] = useState(null); // state to hold the assignments
  const [showOverlay, setShowOverlay] = useState(false); // state to manage overlay visibility
  const [selectedAssignmentId, setSelectedAssignmentId] = useState(null); // state to hold selected assignment ID
  const [selectedClassName, setSelectedClassName] = useState("");
  const [currentAssignment, setCurrentAssignment] = useState(null);
  const [selectedStudentId, setSelectedStudentId] = useState(null); // state to hold selected student ID
  const { handleError } = useError(); // Destructure handleError from the context

  const circleSize = 55; // Define the circle size
  const iconSize = circleSize - 22; // Calculate the icon size

  // Function to return color based on status
  function getStatusColor(status) {
    switch (status) {
      case "finished":
        return "green";
      case "failed":
        return "red";
      case "grading":
        return "blue";
      default:
        return "grey"; // For 'unknown' and 'not_submitted'
    }
  }

  useEffect(() => {
    fetchAssignments();
  }, [classId, newAssignmentFlag, refreshAssignments]); // Add refreshAssignments as a dependency

  // Ref to store interval ID for cleanup
  const pollingIntervalId = useRef(null);

  // Function to update assignment status
  const updateAssignmentStatus = async (assignmentId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/job_position_by_assignment?assignment_id=${assignmentId}`,
        {
          headers: {
            Authorization: Cookies.get("token"),
          },
        }
      );

      // Destructure the response data
      const { assignment_id, status, position } = response.data;

      // Update the assignment status in the state
      setAssignments((prevAssignments) =>
        prevAssignments.map((assignment) =>
          assignment.id === assignment_id
            ? { ...assignment, job_status: status, queue_position: position }
            : assignment
        )
      );
    } catch (error) {
      console.error("Failed to update assignment status", error);
      handleError("Failed to update assignment status");
    }
  };

  // Setup polling
  useEffect(() => {
    const assignmentsNeedingUpdate = assignments?.filter(
      (a) => a.job_status === "grading" || a.job_status === "queued"
    );

    if (assignmentsNeedingUpdate?.length > 0) {
      pollingIntervalId.current = setInterval(() => {
        assignmentsNeedingUpdate.forEach((assignment) => {
          updateAssignmentStatus(assignment.id);
        });
      }, 30000); // Poll every 30 seconds
    }

    // Cleanup on unmount or when no assignments need polling
    return () => {
      if (pollingIntervalId.current) {
        clearInterval(pollingIntervalId.current);
      }
    };
  }, [assignments]); // Including assignments as a dependency here requires careful consideration to avoid infinite loops

  // Optional: Function to format or modify how the status is displayed
  function formatStatus(status) {
    // Example: Replace underscores with spaces and capitalize first letter
    const formatted = status.replace("_", " ").toLowerCase();
    return formatted.charAt(0).toUpperCase() + formatted.slice(1);
  }

  // Function to close the overlay
  function closeOverlay() {
    setShowOverlay(false);
    setSelectedAssignmentId(null);
    setSelectedStudentId(null); // Clear the selected student ID
    // Additional cleanup actions as needed
    fetchAssignments(); // Call fetchAssignments directly
  }

  function handleAssignmentClick(assignmentId, assignmentTitle) {
    setSelectedAssignmentId(assignmentId);
    setSelectedClassName(assignmentTitle);
    const selectedAssignment = assignments.find(
      (assignment) => assignment.id === assignmentId
    );
    setCurrentAssignment(selectedAssignment);

    if (selectedAssignment.assignment_type === "auto_grade") {
      // For auto_grade assignments, show the assignment component directly
      setShowOverlay(true);
      setSelectedStudentId(null); // Optionally reset or bypass student selection
    } else {
      // For other types, proceed as before
      setShowOverlay(true);
    }
  }

  function closeOverlay() {
    setShowOverlay(false);
    setSelectedAssignmentId(null);
    setSelectedStudentId(null); // Clear the selected student ID
    fetchAssignments();
  }

  const fetchAssignments = async () => {
    try {
      const token = Cookies.get("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/get_teacher_assignments?class_id=${classId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // Sort assignments by created_at in descending order
      const sortedAssignments = response.data.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      setAssignments(sortedAssignments);
    } catch (error) {
      console.error("Failed to fetch assignments", error);
      handleError("Failed to fetch assignments"); // Use handleError
    }
  };

  let assignmentComponent = null;
  if (currentAssignment) {
    switch (currentAssignment.assignment_type) {
      case "auto_grade":
        assignmentComponent = (
          <TeacherAutoGradeAssignment
            assignmentId={selectedAssignmentId}
            onCloseOverlay={closeOverlay} // Pass closeOverlay as a prop
          />
        );
        break;

      default:
        break;
    }
  }

  function handleDeleteAssignment(assignmentId) {
    if (window.confirm("Are you sure you want to delete this assignment?")) {
      const token = Cookies.get("token");
      axios
        .delete(
          `${process.env.REACT_APP_API_URL}/delete_assignment/${assignmentId}`,
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            // Remove the assignment from the state
            setAssignments(
              assignments.filter((assignment) => assignment.id !== assignmentId)
            );
          }
        })
        .catch((error) => {
          console.error("Failed to delete assignment", error);
          // Optionally handle the error, e.g., show an error message to the user
          handleError("Failed to delete assignment"); // Use handleError
        });
    }
  }

  return (
    <div style={{ width: "100%" }}>
      {assignments ? (
        assignments.length > 0 ? (
          assignments.map((assignment, index) => (
            <div
              key={index}
              className="custom-card mb-3 me-3"
              style={{
                width: "100%",
                maxWidth: "100%",
                height: "auto", // Adjusted to auto to accommodate additional text
                padding: "22px",
                cursor: "pointer",
                overflow: "hidden",
                position: "relative",
              }}
              onClick={() =>
                handleAssignmentClick(assignment.id, assignment.title)
              }
            >
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteAssignment(assignment.id);
                }}
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  backgroundColor: "white",
                  border: "none",
                  borderRadius: "10px",
                  padding: "5px",
                  cursor: "pointer",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                </svg>
              </button>
              <div
                style={{
                  width: `${circleSize}px`,
                  height: `${circleSize}px`,
                  borderRadius: "50%",
                  background: "#ababab",
                  marginRight: "22px",
                  flexShrink: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={iconSize}
                  height={iconSize}
                  fill="white"
                  class="bi bi-robot"
                  viewBox="0 0 16 16"
                  style={{ marginBottom: "3px", marginLeft: "1px" }}
                >
                  <path d="M6 12.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5M3 8.062C3 6.76 4.235 5.765 5.53 5.886a26.6 26.6 0 0 0 4.94 0C11.765 5.765 13 6.76 13 8.062v1.157a.93.93 0 0 1-.765.935c-.845.147-2.34.346-4.235.346s-3.39-.2-4.235-.346A.93.93 0 0 1 3 9.219zm4.542-.827a.25.25 0 0 0-.217.068l-.92.9a25 25 0 0 1-1.871-.183.25.25 0 0 0-.068.495c.55.076 1.232.149 2.02.193a.25.25 0 0 0 .189-.071l.754-.736.847 1.71a.25.25 0 0 0 .404.062l.932-.97a25 25 0 0 0 1.922-.188.25.25 0 0 0-.068-.495c-.538.074-1.207.145-1.98.189a.25.25 0 0 0-.166.076l-.754.785-.842-1.7a.25.25 0 0 0-.182-.135" />
                  <path d="M8.5 1.866a1 1 0 1 0-1 0V3h-2A4.5 4.5 0 0 0 1 7.5V8a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1v1a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-1a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1v-.5A4.5 4.5 0 0 0 10.5 3h-2zM14 7.5V13a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7.5A3.5 3.5 0 0 1 5.5 4h5A3.5 3.5 0 0 1 14 7.5" />
                </svg>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                <h4
                  className="card-title"
                  style={{ fontSize: "1.5rem", fontWeight: "600" }}
                >
                  {assignment.title}
                </h4>
                <p
                  className="card-text"
                  style={{
                    fontSize: "1rem",
                    fontWeight: "normal",
                    marginBottom: "0px",
                  }}
                >
                  {assignment.description}
                </p>
                <div
                  style={{
                    marginTop: "5px", // Ensures spacing between the description and status
                  }}
                >
                  <span
                    style={{
                      fontSize: "0.9rem",
                      fontWeight: "bold",
                      color: getStatusColor(assignment.job_status),
                    }}
                  >
                    Status: {formatStatus(assignment.job_status || "")}
                    {assignment.queue_position
                      ? ` (Position in queue: ${assignment.queue_position})`
                      : ""}
                  </span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div
            className="card mb-3 me-3"
            style={{ width: "100%", height: "100px" }}
          >
            <div className="card-body">
              <h4 className="card-title">
                This is Where Assignments Will be Displayed!
              </h4>
              <p className="card-text">Create your first assignment today!</p>
            </div>
          </div>
        )
      ) : (
        <p>Loading...</p>
      )}

      {showOverlay && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            display: showOverlay ? "flex" : "none",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
            opacity: showOverlay ? 1 : 0,
            transition: "opacity 0.3s ease-in-out",
            backgroundColor: "rgb(243, 244, 246)",
          }}
        >
          <div
            className="d-block w-100"
            style={{
              paddingLeft: "20px",
              paddingTop: "20px",
            }}
          >
            <button
              className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground h-10 w-10"
              onClick={closeOverlay}
              style={{
                transition:
                  "background-color .3s ease, color .3s ease, transform 0.3s ease, border-color .3s ease",
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = "#d9d7d7"; // Light gray background on hover
                e.currentTarget.style.transform = "scale(1.15)"; // Scale up on hover
                e.currentTarget.style.borderColor = "black"; // Darker border on hover
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = "initial"; // Revert to default background color
                e.currentTarget.style.transform = "scale(1)"; // Scale up on hover
                e.currentTarget.style.borderColor = "initial"; // Revert to default border color
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="h-4 w-4"
              >
                <path d="m12 19-7-7 7-7"></path>
                <path d="M19 12H5"></path>
              </svg>
              <span className="sr-only">Back</span>
            </button>
            <h1
              className="font-semibold text-lg md:text-2xl inline-block"
              style={{ marginLeft: "20px" }}
            >
              {selectedClassName}
            </h1>
          </div>
          <TeacherAutoGradeAssignment
            assignmentId={selectedAssignmentId}
            onCloseOverlay={closeOverlay} // Pass closeOverlay as a prop
          />
        </div>
      )}
    </div>
  );
}

export default TeacherAssignmentCard;
