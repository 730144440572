import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import AutoGradeResults from "./AutoGradeResults"; // Adjust the path as needed
import AssignmentUploader from "./AssignmentUploader"; // Adjust the path as needed
import ScannerOrUpload from "./ScannerOrUpload";
import ScannerCard from "./ScannerCard";

function TeacherAutoGradeAssignment({ assignmentId, onCloseOverlay }) {
  const [gradingSettings, setGradingSettings] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [imageFiles, setImageFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isGrading, setIsGrading] = useState(false);
  const [formatChoice, setFormatChoice] = useState(null);
  const [requestSubscription, setRequestSubscription] = useState(false);
  const [scannerUrl, setScannerUrl] = useState(null);
  const [hasSubmissions, setHasSubmissions] = useState(false); // New state to track if there are submissions

  const handleFormatChoice = (choice) => {
    setFormatChoice(choice);
    console.log(`Format Chosen: ${choice}`); // Optional: for demonstration
  };

  const fetchScannerUrl = async () => {
    const token = Cookies.get("token"); // Ensure you have the token available
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/create_scanner_upload`,
        { uploadType: "groundtruth" },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      if (response.data && response.data.accessCode) {
        setScannerUrl(response.data.accessCode); // Update state with the scanner URL
      }
    } catch (error) {
      console.error("Error fetching scanner URL:", error);
      // handleError("Failed to fetch scanner URL"); // Implement or use an existing error handling function
      setErrorMessage("Failed to fetch scanner URL"); // Assuming you have a state for managing error messages
    }
  };

  useEffect(() => {
    if (formatChoice === "Image") {
      fetchScannerUrl(); // Fetch the scanner URL when "Image" is selected
    }
  }, [formatChoice]); // This useEffect reacts to changes in formatChoice

  const Spinner = () => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        className="loader"
        style={{ marginRight: "20px", width: "40px", height: "40px" }}
      ></div>
      <span
        style={{
          fontSize: "3rem",
          color: "rgb(130, 130, 130)",
          fontWeight: "500",
        }}
      >
        {isUploading ? "Uploading Assignments..." : "Grading Assignments..."}
      </span>
    </div>
  );

  useEffect(() => {
    const fetchGradingSettings = async () => {
      const token = Cookies.get("token"); // Retrieve the token from cookies

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/get_submissions?assignment_id=${assignmentId}`,
          {
            headers: {
              Authorization: token, // Use the token from cookies
              "Content-Type": "application/json",
            },
          }
        );
        console.log("called get submissions");
        if (
          response.data &&
          Array.isArray(response.data) &&
          response.data.length === 0
        ) {
          setHasSubmissions(false);
          console.log("has submissions set to false due to empty array");
        } else {
          setGradingSettings(response.data);
          setHasSubmissions(true);
          console.log("has submissions set to true");
        }
      } catch (error) {
        console.error("Error fetching grading settings:", error);
        setErrorMessage("Failed to fetch grading settings");
      }
      setIsLoading(false);
    };

    fetchGradingSettings();
  }, [assignmentId]);

  const handleFileChange = (event) => {
    if (event.target.files.length > 30) {
      alert("You't upload more than 30 files at once.");
      return;
    }
    setImageFiles(Array.from(event.target.files));
  };

  const uploadFiles = async () => {
    setIsUploading(true); // Set uploading state to true
    const token = Cookies.get("token");

    const formData = new FormData();
    formData.append("assignment_id", assignmentId); // Directly appending assignmentId without JSON.stringify

    imageFiles.forEach((file) => {
      formData.append("files", file); // Add files to form data
    });

    try {
      const uploadResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/enqueue_submission`,
        formData,
        {
          headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (uploadResponse.status >= 200 && uploadResponse.status < 300) {
        setIsGrading(true); // Indicate grading is in progress
        setIsUploading(false); // Set uploading to false
        onCloseOverlay(); // Call the close overlay callback
      }
    } catch (error) {
      console.error("Error during file upload and grading:", error);
      setIsUploading(false); // Ensure we reset the uploading state in case of error
      console.log(error.response.data)
      if (error.response.data.error === 'User is not subscribed') setRequestSubscription(true);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isUploading || isGrading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "calc(100vh - 300px)", // Assuming you want to take the full viewport height
          width: "100%",
          marginBottom: "50px",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const handleBackToFileUpload = () => {
    console.log("Back to file upload triggered");
    // Set gradingSettings to mimic the desired endpoint response

    setHasSubmissions(false);
    setFormatChoice(null);
    setGradingSettings({ message: "No submissions uploaded yet" });
    setImageFiles([]); // Clear any selected files
  };

  const handleSubscription = async () => {
    const token = Cookies.get("token");
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/create_checkout_session`,
        {},
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => (window.location = response.data.url));
  };

  // Function to handle the manual upload trigger
  const handleManualUpload = () => {
    setFormatChoice("PDF"); // Directly set the formatChoice to "PDF"
  };

  if (errorMessage) {
    return <div>Error: {errorMessage}</div>;
  }

  if (requestSubscription) {
    // Render Subscription Screen
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <h2>You need to subscribe to grade additional submissions</h2>
        <button
          onClick={handleSubscription}
          style={{
            backgroundColor: "black",
            color: "white",
            border: "none",
            borderRadius: "5px",
            padding: "10px 20px",
            cursor: "pointer",
            fontSize: "1.2rem",
          }}
        >
          Subscribe
        </button>
      </div>
    );
  }

  if (!hasSubmissions && formatChoice === null) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <div
          className="max-w-2xl"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <ScannerOrUpload onFormatChosen={handleFormatChoice} />
        </div>
      </div>
    );
  }

  // Based on formatChoice, render the appropriate component
  if (formatChoice === "PDF") {
    // User chose to upload files
    return (
      <AssignmentUploader
        imageFiles={imageFiles}
        handleFileChange={handleFileChange}
        handleUpload={uploadFiles}
      />
    );
  }

  if (formatChoice === "Image") {
    return scannerUrl ? (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "90%",
          width: "100%",
          marginBottom: "100px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <ScannerCard
            assignmentID={assignmentId}
            url={scannerUrl}
            onScanFinished={onCloseOverlay}
            onManualUpload={handleManualUpload}
          />
        </div>
      </div>
    ) : (
      <div>Loading scanner...</div>
    );
  }

  return (
    <AutoGradeResults
      assignmentId={assignmentId}
      onBackToFileUpload={handleBackToFileUpload}
    />
  );
}

export default TeacherAutoGradeAssignment;
