import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ForgotPasswordComponent = () => {
  const [email, setEmail] = useState("");
  const [isRequestSuccessful, setIsRequestSuccessful] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // New state for error message
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsRequestSuccessful(false);
    setErrorMessage(""); // Reset error message on new submission
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/generate_reset_token`,
        { email }
      );
      console.log(response.data);
      setIsRequestSuccessful(true);
      setErrorMessage(""); // Clear any previous error message
    } catch (error) {
      console.error("Error:", error);
      setIsRequestSuccessful(false);
      // Parse and display error message from server response
      const message =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "An unexpected error occurred";
      setErrorMessage(message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoginClick = (e) => {
    e.preventDefault();
    navigate("/login");
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="rounded-lg border bg-white text-gray-800 shadow-lg mx-auto max-w-sm">
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col p-6 space-y-1">
            <h3 className="whitespace-nowrap tracking-tight text-2xl font-bold">
              Reset password
            </h3>
            <p className="text-sm text-gray-600">
              Enter your email below to reset your password
            </p>
          </div>
          <div className="p-6 pt-0">
            {/* Display error message if present */}
            {errorMessage && (
              <div className="text-red-500 text-sm mb-4">{errorMessage}</div>
            )}
            {isRequestSuccessful && (
              <div className="mb-4 text-sm text-green-600">
                An email has been sent to {email} with instructions to reset
                your password.
              </div>
            )}
            <div className="space-y-4">
              <div className="space-y-2">
                <label className="text-sm font-medium" htmlFor="email">
                  Email
                </label>
                <input
                  className="flex h-10 w-full rounded-md border border-gray-300 bg-gray-50 px-3 py-2 text-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  id="email"
                  placeholder="janedoe@example.com"
                  required
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <button
                className="inline-flex items-center justify-center rounded-md text-sm font-medium bg-black text-white w-full h-10 px-4 py-2 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 hover:bg-black/90"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <div
                    className="spinner-border animate-spin inline-block w-2.25 h-2.25 border-4 rounded-full text-gray-500"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
            <div className="mt-4 text-center text-sm">
              Remembered your password?{" "}
              <a
                className="underline text-gray-600 hover:text-gray-800 cursor-pointer"
                onClick={handleLoginClick}
              >
                Login
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPasswordComponent;
