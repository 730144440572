import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import TeacherHomePage from "./pages/TeacherHomePage";
import TeacherClassPage from "./pages/TeacherClassPage";
import Scanner from "./pages/Scanner";
import "bootstrap-icons/font/bootstrap-icons.css";
import { ErrorProvider } from "./ErrorContext";
import GlobalErrorHandler from "./GlobalErrorHandler";
import ForgotPasswordComponent from "./components/ForgotPasswordComponent";
import ResetPasswordPage from "./pages/ResetPassswordPage";

function App() {
  return (
    <ErrorProvider>
      <Router>
        <GlobalErrorHandler />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/dashboard" element={<TeacherHomePage />} />
          <Route
            path="/forgot-password"
            element={<ForgotPasswordComponent />}
          />
          <Route path="/class/:classId" element={<TeacherClassPage />} />
          <Route path="/scanner/:url" element={<Scanner />} />
          <Route
            path="/password-reset/:token"
            element={<ResetPasswordPage />}
          />
        </Routes>
      </Router>
    </ErrorProvider>
  );
}

export default App;
