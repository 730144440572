import React from 'react';
import LoginForm from '../components/LoginForm';

function LoginPage() {
  return (
    <LoginForm />
  );
}

export default LoginPage;
