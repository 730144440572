import React, { useState, useEffect, useRef } from "react";
import Cookies from "js-cookie"; // Ensure you have imported js-cookie
import axios from "axios";
import { useError } from "../../../ErrorContext";

const ReviewAnswers = ({ questions, onDeleteIncorrectAnswer }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [reviewedQuestions, setReviewedQuestions] = useState([]);
  const { handleError } = useError(); // Destructure handleError from the context

  console.log(questions);

  const questionRef = useRef(null);
  const studentAnswerRef = useRef(null);
  const feedbackRef = useRef(null);

  useEffect(() => {
    // Logic here to handle updated questions prop, if necessary
    // For example, reset currentQuestionIndex if questions array changes
    setCurrentQuestionIndex(0);
  }, [questions]);

  useEffect(() => {
    const storedReviewedQuestions = localStorage.getItem("reviewedQuestions");
    if (storedReviewedQuestions) {
      setReviewedQuestions(JSON.parse(storedReviewedQuestions));
    }
  }, []);

  const filteredQuestions = questions.filter(
    (question) =>
      !reviewedQuestions.includes(
        `${question.submissionId}-${question.question}`
      )
  );

  const currentQuestion = filteredQuestions[currentQuestionIndex];

  const overrideStatusAndNext = (status) => {
    console.log(
      `Override question ${questions[currentQuestionIndex].submission_id} as ${status}`
    );
    // Add logic to update the status in the backend here
    // If overriding as correct, delete the incorrect answer
    if (status === "Correct") {
      handleDeleteIncorrectAnswer(currentQuestion.question_id);
    }
    const questionIdentifier = generateQuestionIdentifier(currentQuestion);
    const newReviewedQuestions = [...reviewedQuestions, questionIdentifier];
    setReviewedQuestions(newReviewedQuestions);
    localStorage.setItem(
      "reviewedQuestions",
      JSON.stringify(newReviewedQuestions)
    );

    // Advance to the next question
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handleDeleteIncorrectAnswer = (question_id) => {
    const token = Cookies.get("token");
    const osmosSettingsId = currentQuestion.submission_id;

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/update_question_correctness`,
        {
          submission_id: osmosSettingsId,
          question_index: question_id,
          correct: true,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      )
      .then((response) => {
        const data = response.data;
        if (data.message === "Question correctness updated successfully") {
          onDeleteIncorrectAnswer(); // Pass student name and new grade percentage to the parent component
        } else {
          console.error("Failed to delete the incorrect answer:", data.message);
          handleError("Failed to delete the incorrect answer"); // Use handleError
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        handleError("Failed to delete the incorrect answer"); // Use handleError
      });
  };

  // Function to generate unique identifier for each question
  const generateQuestionIdentifier = (question) => {
    return `${question.submission_id}-${question.question}`;
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      {currentQuestion ? (
        <div
          className="rounded-lg border bg-white text-card-foreground shadow-sm flex flex-col"
          data-v0-t="card"
          style={{ height: "100%" }}
        >
          <div className="p-6">
            <div className="flex flex-col space-y-1.5">
              <h3 className="text-3xl font-semibold whitespace-nowrap leading-none tracking-tight">
                Review All Incorrect Answers
              </h3>
              <p className="text-l text-muted-foreground">
                Current Student: {currentQuestion.studentName}
              </p>
            </div>
          </div>
          <div className="overflow-y-auto">
            <div
              className="flex flex-col h-full p-6 pt-0"
              style={{ height: "100%" }}
            >
              <div className="flex flex-col flex-grow border-l-4 border-gray-300 pl-4 mb-3 pt-2">
                <label
                  className="text-l font-medium"
                  htmlFor={`question-${currentQuestionIndex}`}
                >
                  Question
                </label>
                <p
                  id={`question-${currentQuestionIndex}`}
                  className="text-l text-gray-500 dark:text-gray-400 flex-grow"
                  ref={questionRef}
                >
                  {currentQuestion.question}
                </p>
              </div>
              <div className="flex flex-col flex-grow border-l-4 border-gray-400 pl-4 mb-3 pt-2">
                <label
                  className="text-l font-medium"
                  htmlFor={`student-answer-${currentQuestionIndex}`}
                >
                  Correct Answer
                </label>
                <p
                  id={`student-answer-${currentQuestionIndex}`}
                  className="text-l text-gray-500 dark:text-gray-400 flex-grow"
                  ref={studentAnswerRef}
                >
                  {currentQuestion.correctAnswer}
                </p>
              </div>
              <div className="flex flex-col flex-grow border-l-4 border-gray-400 pl-4 mb-3 pt-2">
                <label
                  className="text-l font-medium"
                  htmlFor={`student-answer-${currentQuestionIndex}`}
                >
                  Student's Answer
                </label>
                <p
                  id={`student-answer-${currentQuestionIndex}`}
                  className="text-l text-gray-500 dark:text-gray-400 flex-grow"
                  ref={studentAnswerRef}
                >
                  {currentQuestion.studentAnswer}
                </p>
              </div>
              <div className="flex flex-col flex-grow border-l-4 border-gray-500 pl-4 pt-2">
                <label
                  className="text-l font-medium"
                  htmlFor={`feedback-${currentQuestionIndex}`}
                >
                  Feedback
                </label>
                <p
                  id={`feedback-${currentQuestionIndex}`}
                  className="text-l text-gray-500 dark:text-gray-400 flex-grow"
                  ref={feedbackRef}
                >
                  {currentQuestion.explanation}
                </p>
              </div>
            </div>
          </div>

          <div className="flex justify-between px-6 pb-6">
            <button
              onClick={() => overrideStatusAndNext("Incorrect")}
              className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground h-10"
              style={{
                width: "60px",
                transition:
                  "background-color .3s ease, color .3s ease, transform 0.3s ease, border-color .3s ease",
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = "#f5f5f5"; // Light gray background on hover
                e.currentTarget.style.transform = "scale(1.1)"; // Scale up on hover
                e.currentTarget.style.borderColor = "black"; // Darker border on hover
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = "initial"; // Revert to default background color
                e.currentTarget.style.transform = "scale(1)"; // Scale up on hover
                e.currentTarget.style.borderColor = "initial"; // Revert to default border color
              }}
            >
              Next
            </button>
            <button
              onClick={() => overrideStatusAndNext("Correct")}
              className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground h-10"
              style={{
                width: "160px",
                transition:
                  "background-color .3s ease, color .3s ease, transform 0.3s ease, border-color .3s ease",
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = "#ff474e"; // Light gray background on hover
                e.currentTarget.style.transform = "scale(1.1)"; // Scale up on hover
                e.currentTarget.style.borderColor = "black"; // Darker border on hover
                e.currentTarget.style.color = "white"; // Change font color to white on hover
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = "initial"; // Revert to default background color
                e.currentTarget.style.transform = "scale(1)"; // Scale up on hover
                e.currentTarget.style.borderColor = "initial"; // Revert to default border color
                e.currentTarget.style.color = "initial"; // Revert to default font color
              }}
            >
              Override as Correct
            </button>
          </div>
        </div>
      ) : (
        <div
          className="rounded-lg border bg-white text-card-foreground shadow-sm w-full flex flex-col"
          data-v0-t="card"
          style={{ height: "100%" }}
        >
          <div className="p-6">
            <div className="flex flex-col space-y-1.5">
              <h3 className="text-2xl font-semibold whitespace-nowrap leading-none tracking-tight">
                Review All Incorrect Answers
              </h3>
              <p
                className="text-sm text-muted-foreground"
                style={{ maxWidth: "90%" }}
              >
                No more incorrect questions to review, you've either reviewed
                them all or all quesitons were answered correctly
              </p>
            </div>
          </div>
          <div
            className="bg-card text-card-foreground border border-gray-200 rounded-lg shadow-sm p-6 flex flex-col items-center justify-center space-y-4 dark:border-gray-800"
            style={{
              width: "calc(100% - 40px)",
              height: "100%",
              marginLeft: "20px",
              marginBottom: "20px",
              backgroundColor: "#fefefe",
            }}
            data-v0-t="card"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-500 dark:text-green-300"
            >
              <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
            <div className="text-center">
              <h3
                className="whitespace-nowrap tracking-tight font-semibold"
                style={{ fontSize: "1.5rem" }}
              >
                All questions answered correctly
              </h3>
              <p
                className=" text-gray-500 dark:text-gray-400"
                style={{ fontSize: "1rem" }}
              >
                Good on you, amazing teaching!
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReviewAnswers;
