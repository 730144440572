import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate, Link } from "react-router-dom"; // Import Link and useNavigate hook
import { GoogleLogin } from "@react-oauth/google"; // Import GoogleLogin
import logo from "../osmos-logo.png";
import ReCAPTCHA from "react-google-recaptcha"; // Import ReCAPTCHA component
import "../index.css";
import { useError } from "../ErrorContext"; // Import useError hook

function RegisterForm() {
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null); // new state for error message
  const [showErrorGlow, setShowErrorGlow] = useState(false);
  const { handleError } = useError(); // Destructure handleError from the context
  const [agreedToTerms, setAgreedToTerms] = useState(false); // New state for checkbox
  const [captchaValue, setCaptchaValue] = useState(null); // State to store captcha response

  const navigate = useNavigate(); // Get the navigate function
  const recaptchaRef = useRef(null); // Ref for the reCAPTCHA instance

  const isFormFilled =
    first !== "" &&
    last !== "" &&
    password !== "" &&
    email !== "" &&
    agreedToTerms; // Update condition to include agreedToTerms

  const checkCircleIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-check-circle-fill"
      viewBox="0 0 16 16"
    >
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
    </svg>
  );

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!isFormFilled) {
      setError("Please fill in all fields and agree to the terms of service.");
      return;
    }

    // Execute reCAPTCHA when the form is submitted
    const recaptchaInstance = recaptchaRef.current;
    if (recaptchaInstance) {
      recaptchaInstance
        .execute()
        .then((token) => {
          // Proceed with registration after getting the reCAPTCHA token
          registerUser(token);
        })
        .catch((error) => {
          console.error("ReCAPTCHA verification failed", error);
          setError("ReCAPTCHA verification failed. Please try again.");
        });
    } else {
      console.error("ReCAPTCHA not loaded");
      setError("ReCAPTCHA not loaded. Please refresh the page and try again.");
    }
  };

  // Refactored registration logic into a separate function for clarity
  const registerUser = async (captchaToken) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/register`,
        {
          first,
          last,
          email,
          password,
          captcha: captchaToken, // Include the reCAPTCHA token in the request
        }
      );

      Cookies.set("token", response.data.token);
      setError(null);
      navigate("/dashboard");
    } catch (error) {
      // Handle registration errors
      console.error("Registration error:", error);
      // Check if the error response has a data object with a message property
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        // Set the error message from the response
        setError(error.response.data.message);
      } else {
        // Set a generic error message if the response does not contain a message
        setError("An error occurred during registration. Please try again.");
      }
    }
  };

  // Function to handle Google login success
  const handleGoogleLogin = async (googleData) => {
    try {
      // Send Google ID token to your backend
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/google_login`,
        {
          id_token: googleData.credential,
        }
      );

      // Store the received JWT token in a cookie
      Cookies.set("token", response.data.token);

      // Redirect to different pages based on the token
      if (response.data.token.startsWith("Teacher")) {
        navigate("/dashboard");
      }
    } catch (error) {
      console.error("Error processing Google login", error);
      handleError("Failed to process Google login"); // Use handleError
      // Handle errors here, such as displaying a message to the user
    }
  };

  return (
    <div className="row" style={{ display: "flex", height: "100vh" }}>
      <div
        className="col-lg-4"
        style={{
          background:
            "radial-gradient(circle, rgba(246,246,246,1) 0%, rgba(224,224,224,1) 60%)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",

          justifyContent: "center",
          padding: "20px",
          textAlign: "left",
        }}
      >
        <div
          style={{
            color: "#000000",
            fontSize: "1.6rem",
            fontWeight: "500",
            textAlign: "left",
            width: "400px",
          }}
        >
          Never Grade a Worksheet Again!
        </div>
        <ul
          style={{
            listStyleType: "none",
            padding: "10px 0 0",
            margin: 0,
            width: "400px",
          }}
        >
          {[
            "Reduce time spent grading by 40%",
            "No more weekends lost to mountains of worksheets",
            "Free yourself to give more to your students",
          ].map((item) => (
            <li
              key={item}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "5px",
                marginRight: "40px",
                width: "400px",
              }}
            >
              {checkCircleIcon}
              <span style={{ marginLeft: "10px", fontSize: "1rem" }}>
                {item}
              </span>
            </li>
          ))}
        </ul>
      </div>

      <div
        className="col-lg-8"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "360px",
            padding: "28px",
            border: "0.5px solid #d3d3d3",
            borderRadius: "5px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={logo}
            alt="Osmos Logo"
            style={{ width: "200px", marginBottom: "15px" }}
          />
          <h2
            style={{
              fontSize: "1.9rem",
              marginBottom: "3px",
              paddingTop: "10px",
              fontWeight: "600",
            }}
          >
            Register
          </h2>
          <p style={{ fontSize: ".9rem", marginBottom: "15px" }}>
            to continue to Osmos
          </p>
          {error && (
            <div
              className="alert alert-danger"
              style={{
                height: "60px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "270px",
              }}
            >
              {error}
            </div>
          )}
          <input
            type="text"
            className={`form-control ${showErrorGlow ? "errorGlow" : ""}`}
            placeholder="First name"
            value={first}
            onChange={(e) => setFirst(e.target.value)}
            style={{
              margin: "5px 0",
              height: "35px",
              width: "270px",
              fontSize: "0.8rem",
              borderRadius: "3px",
            }}
          />
          <input
            type="text"
            className={`form-control ${showErrorGlow ? "errorGlow" : ""}`}
            placeholder="Last name"
            value={last}
            onChange={(e) => setLast(e.target.value)}
            style={{
              margin: "5px 0",
              height: "35px",
              width: "270px",
              fontSize: "0.8rem",
              borderRadius: "3px",
            }}
          />
          <input
            type="email"
            className={`form-control ${showErrorGlow ? "errorGlow" : ""}`}
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{
              margin: "5px 0",
              height: "35px",
              width: "270px",
              fontSize: "0.8rem",
              borderRadius: "3px",
            }}
          />
          <input
            type="password"
            className={`form-control ${showErrorGlow ? "errorGlow" : ""}`}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{
              margin: "5px 0",
              height: "35px",
              width: "270px",
              fontSize: "0.8rem",
              borderRadius: "3px",
            }}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "10px 0",
              padding: "15px",
              paddingTop: "0px",
              paddingBottom: "0px",
            }}
          >
            <input
              type="checkbox"
              checked={agreedToTerms}
              onChange={(e) => setAgreedToTerms(e.target.checked)}
              style={{ marginLeft: "5px", marginRight: "10px" }}
            />
            <label style={{ fontSize: ".8rem" }}>
              I Agree to the{" "}
              <a href="https://osmoslearn.com/terms-of-service">
                Terms of Service
              </a>{" "}
              and the{" "}
              <a href="https://osmoslearn.com/privacy-policy">Privacy Policy</a>
              .
            </label>
          </div>
          <button
            className={`btn btn-primary ${showErrorGlow ? "errorGlow" : ""}`}
            onClick={handleSubmit}
            style={{
              margin: "5px 0",
              width: "270px",
              backgroundColor: isFormFilled ? "#0d6efd" : "#add8e6",
              border: "none",
              fontSize: "0.8rem",
              borderRadius: "3px",
              height: "35px",
              cursor: isFormFilled ? "pointer" : "default", // Conditional cursor style
            }}
          >
            Register
          </button>

          <hr style={{ width: "270px", margin: "20px 0" }} />
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6Lf4nX4pAAAAAJ1AFhaxUCITyY5mFbjH1BSQ1jhT"
            size="invisible"
            badge="invisible" // Place reCAPTCHA badge inline with other elements or as per your design needs
          />
          <GoogleLogin
            onSuccess={handleGoogleLogin}
            onError={() => console.log("Login Failed")}
            style={{ width: "270px" }} // Adjusted width to 270px
          />
          <div style={{ marginTop: "15px" }}>
            <Link
              to="/login"
              style={{
                color: "gray",
                textDecoration: "underline",
              }}
            >
              Existing user?
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterForm;
