import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import StudentsTable from "./auto_grade/StudentsTable";
import StudentDetailsCard from "./auto_grade/StudentDetailsCard";
import ReviewAnswerCard from "./auto_grade/ReviewAnswerCard";

function AutoGradeResults({ assignmentId, onBackToFileUpload }) {
  const [students, setStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [assignmentInfo, setAssignmentInfo] = useState({});
  const [assignmentInfoFetched, setAssignmentInfoFetched] = useState(false);
  const [incorrectQuestions, setIncorrectQuestions] = useState([]);
  const [updateKey, setUpdateKey] = useState(0);

  // New function to handle 'Add Assignments' button click in StudentsTable
  const handleAddAssignment = () => {
    onBackToFileUpload(); // Call the onBackToFileUpload prop when the button is clicked
  };

  const onDataChange = () => {
    // This function is triggered when student data changes. Fetch updated submissions.
    fetchGradingSettings(); // Call the existing function to re-fetch submissions.
  };

  const updateStudentGrade = () => {
    fetchGradingSettings(); // Call the existing function to re-fetch submissions.
  };

  const handleDeleteStudentCallback = (deletedStudentName) => {
    console.log(
      "handleDeleteStudentCallback called with student name:",
      deletedStudentName
    );

    const updatedStudents = students.filter(
      (student) => student.name !== deletedStudentName
    );

    setStudents(updatedStudents);
    setSelectedStudent(null); // Clear the selection

    // Check if there are no students left and call onBackToFileUpload
    if (updatedStudents.length === 0) {
      console.log("No students left, navigating back to file upload.");
      onBackToFileUpload();
    }

    // Update the component key to force re-render, if necessary
    setUpdateKey((prevKey) => prevKey + 1);
  };

  // Function to handle closing the student details card
  const handleCloseStudentDetails = () => {
    setSelectedStudent(null);
  };

  const handleStudentClick = (student) => {
    setSelectedStudent(null);
    // Find the full, updated student object from the students state array
    const detailedStudentData = students.find((s) => s.id === student.id);
    if (detailedStudentData) {
      setSelectedStudent({
        ...detailedStudentData,
      });
    }
  };

  const fetchAssignmentInfo = async () => {
    try {
      const token = Cookies.get("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/assignment_info?assignment_id=` +
          assignmentId,
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      setAssignmentInfo(response.data);
      setAssignmentInfoFetched(true);
    } catch (error) {
      console.error("Error fetching assignment info:", error);
      setErrorMessage("Failed to fetch assignment info");
    }
  };

  const fetchGradingSettings = async () => {
    if (assignmentInfoFetched) {
      const token = Cookies.get("token");

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/get_submissions?assignment_id=${assignmentId}`,
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );

        let incorrect = [];

        const studentsData = response.data.map((item) => {
          const gradePercentage = item.gradePercentage + "%";
          console.log(assignmentInfo);
          console.log(item.questions);

          item.questions.forEach((question, i) => {
            if (!question.correct)
              incorrect.push({
                submission_id: item.id,
                question_id: i,
                question: question.question,
                studentAnswer: question.answer,
                correctAnswer: assignmentInfo.key[i].answer,
                explanation: question.explanation,
                studentName: item.student_name,
              });
          });

          return {
            id: item.id,
            name: item.student_name,
            gradePercentage,
            imageUrls: item.image_urls,
            questions: item.questions,
          };
        });

        setStudents(studentsData);
        console.log(incorrect);
        setIncorrectQuestions(incorrect);
      } catch (error) {
        console.error("Error fetching grading settings:", error);
        setErrorMessage("Failed to fetch grading settings");
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchAssignmentInfo();
  }, [assignmentId]);

  useEffect(() => {
    fetchGradingSettings();
  }, [assignmentInfoFetched]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (errorMessage) {
    return <div>Error: {errorMessage}</div>;
  }

  return (
    <div
      className="container-fluid py-2"
      style={{
        height: "100%",
        backgroundColor: "rgb(243, 244, 246)",
        overflow: "auto",
      }}
    >
      <div className="row h-100">
        <div className="col-lg-6 h-100 overflow-auto">
          <StudentsTable
            key={updateKey}
            students={students}
            onStudentClick={handleStudentClick}
            onAddAssignment={handleAddAssignment}
          />
        </div>
        <div className="col-lg-6 h-100">
          {selectedStudent ? (
            <StudentDetailsCard
              key={selectedStudent.id} // Use the selected student's ID as key
              studentData={selectedStudent}
              assignmentId={assignmentId}
              onDeleteStudent={handleDeleteStudentCallback}
              onClose={handleCloseStudentDetails}
              onDataChange={onDataChange}
            />
          ) : (
            <ReviewAnswerCard
              questions={incorrectQuestions}
              onDeleteIncorrectAnswer={updateStudentGrade}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default AutoGradeResults;
