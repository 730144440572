// GeneratingPage.js
import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import FileUploadComponent from "./FileUploadComponent";
import ChooseFormatComponent from "./ChooseFormatComponent";
import AssignmentScanner from "./AssignmentScanner";
import { useError } from "../../../ErrorContext";

function GeneratingPage({
  isVisible,
  userInput,
  onGenerationComplete,
  onReviewScreenClear,
}) {
  const [imageFiles, setImageFiles] = useState([]); // Changed to hold multiple files
  const [isUploading, setIsUploading] = useState(false);
  const [isRequestComplete, setIsRequestComplete] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [statusMessageOpacity, setStatusMessageOpacity] = useState(0);
  const [requestFinishedEarly, setRequestFinishedEarly] = useState(false);
  const [animationComplete, setAnimationComplete] = useState(false);
  const [questionAndAnswers, setQuestionAndAnswers] = useState([]);
  const [showReviewScreen, setShowReviewScreen] = useState(false);
  const textAreaRefs = useRef([]); // Array to hold refs for each textarea
  const [showInfoTooltip, setShowInfoTooltip] = useState(false);
  const [formatChoice, setFormatChoice] = useState(null);
  const [scannerUrl, setScannerUrl] = useState("");
  const { handleError } = useError(); // Destructure handleError from the context

  const sharedFadeStyle = {
    transition: "opacity 0.5s ease",
    opacity: isVisible ? "1" : "0",
  };

  // Define subheading text based on the formatChoice
  const subheadingText =
    formatChoice === "Image"
      ? "Scan your assignment to auto-generate an answer key."
      : formatChoice === "PDF"
      ? "Upload your assignment as a PDF, or as images."
      : "Choose how you'd like to upload your assignments.";

  useEffect(() => {
    const token = Cookies.get("token");

    if (formatChoice === "Image") {
      const fetchScannerUrl = async () => {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/create_scanner_upload`,
            { uploadType: "groundtruth" },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: token,
              },
            }
          );

          // Change this line to set `scannerUrl` to `response.data.accessCode`
          if (response.data && response.data.accessCode) {
            console.log(response.data.accessCode);
            setScannerUrl(response.data.accessCode);
          }
        } catch (error) {
          console.error("Error fetching scanner URL:", error);
          handleError("Failed to fetch scanner URL"); // Use handleError
          // Handle error appropriately
        }
      };
      console.log(scannerUrl);

      fetchScannerUrl();
    }
  }, [formatChoice]);

  const handlePDFUpload = () => {
    if (imageFiles.length > 5) {
      alert("You can only upload up to 5 images.");
      return; // Stop the function if more than 5 images are selected
    }

    setIsUploading(true);
    const token = Cookies.get("token");
    const formData = new FormData();
    formData.append("context", userInput);

    // Append each file with a unique key
    imageFiles.forEach((file, index) => {
      formData.append(`images_${index}`, file);
    });

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/groundtruth_generation`,
        formData,
        {
          headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (response.data && Array.isArray(response.data.studentResponses)) {
          setQuestionAndAnswers(response.data.studentResponses);
          onGenerationComplete(response.data.studentResponses);
          onReviewScreenClear(response.data.studentResponses);
        } else {
          console.error("Unexpected response format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
        handleError(
          "Error during generation, close and try again! (Assignment may be too long)"
        ); // Use handleError
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  const handleScanFinished = () => {
    console.log("Scan finished!");
    setIsUploading(true);
    const token = Cookies.get("token");

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/groundtruth_generation_code`,
        {
          access_code: scannerUrl,
          context: userInput,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: token,
          },
        }
      )
      .then((response) => {
        if (response.data && Array.isArray(response.data.studentResponses)) {
          setQuestionAndAnswers(response.data.studentResponses);
          onGenerationComplete(response.data.studentResponses);
          onReviewScreenClear(response.data.studentResponses);
        } else {
          // Handle unsuccessful response or errors
          console.error("Error or unsuccessful response:", response);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  const handleRegenerateButtonClick = () => {
    if (imageFiles.length > 0) {
      // Check if there are files in the imageFiles array
      setQuestionAndAnswers([]); // Clear the questions and answers
      setShowReviewScreen(false); // Hide the review screen
      setIsRequestComplete(false); // Reset request completion state
      setIsUploading(true); // Show uploading state
      handleImageUpload(); // Send the request again
    }
  };

  const handleFormatChoice = (choice) => {
    setFormatChoice(choice);
  };

  const buttonStyle = {
    backgroundColor: "black",
    color: "white",
    border: "none",
    borderRadius: "5px",
    padding: "10px 20px",
    cursor: "pointer",
    marginTop: "20px",
    fontSize: "1.2rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "120px",
    height: "40px",
    ...sharedFadeStyle,
  };

  const tooltipStyle = {
    display: showInfoTooltip ? "block" : "none",
    position: "absolute",
    backgroundColor: "#f9f9f9",
    border: "1px solid #d4d4d4",
    padding: "10px",
    zIndex: 1,
    left: "50%", // Center horizontally relative to the SVG
    top: "-110px", // Position above the SVG. Adjust the value as needed
    transform: "translateX(-5%)", // Shift the tooltip leftwards by half its width
    borderRadius: "5px",
    boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
    height: "100px",
    width: "300px",
    // Other styling as required
  };

  const handleDeleteQAPair = (indexToDelete) => {
    setQuestionAndAnswers((prevQAs) => {
      const updatedQAs = prevQAs.filter((_, index) => index !== indexToDelete);
      console.log("Updated questionAndAnswers:", updatedQAs); // Print the updated array
      return updatedQAs;
    });
  };

  const handleAddQAPair = (index) => {
    setQuestionAndAnswers((prevQAs) => [
      ...prevQAs.slice(0, index + 1),
      { question: "", answer: "" },
      ...prevQAs.slice(index + 1),
    ]);
  };

  function handleQuestionAnswerChange(index, type, newValue) {
    setQuestionAndAnswers((prevState) => {
      const updatedItems = [...prevState];
      if (type === "question") {
        updatedItems[index].question = newValue;
      } else {
        updatedItems[index].answer = newValue;
      }
      return updatedItems;
    });
  }

  const handleImageUpload = () => {
    if (imageFiles.length > 5) {
      alert("You can only upload up to 5 images.");
      return; // Stop the function if more than 5 images are selected
    }

    setIsUploading(true);
    const token = Cookies.get("token");
    const formData = new FormData();
    formData.append("context", userInput);

    // Append each file with a unique key
    imageFiles.forEach((file, index) => {
      formData.append(`images_${index}`, file);
    });

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/groundtruth_generation`,
        formData,
        {
          headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (response.data && Array.isArray(response.data.studentResponses)) {
          setQuestionAndAnswers(response.data.studentResponses);
          setShowReviewScreen(true);
        } else {
          console.error("Unexpected response format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  useEffect(() => {
    // Adjust height for all textareas whenever questionAndAnswers changes
    textAreaRefs.current.forEach((textarea) => {
      if (textarea) {
        adjustHeight(textarea);
      }
    });
  }, [questionAndAnswers]); // Depend on questionAndAnswers

  const adjustHeight = (textarea) => {
    textarea.style.height = "auto"; // Reset height
    textarea.style.height = `${textarea.scrollHeight}px`; // Set to scrollHeight
  };

  useEffect(() => {
    if (isUploading) {
      const messages = [
        { text: "Uploading", duration: 3000 },
        { text: "Analyzing", duration: 5000 },
        { text: "Thinking", duration: 3000 },
        { text: "Generating answers", duration: 5000 },
      ];
      let totalTime = 0;

      messages.forEach((message, index) => {
        setTimeout(() => {
          setStatusMessageOpacity(0); // Fade out
          setTimeout(() => {
            setStatusMessage(message.text);
            setStatusMessageOpacity(1); // Fade in

            if (index === messages.length - 1) {
              setAnimationComplete(true);
              if (requestFinishedEarly) {
                setIsRequestComplete(true);
                onGenerationComplete();
              }
            }
          }, 1000); // Fade-out effect duration
        }, totalTime);
        totalTime += message.duration;
      });
    }

    // Reset state when isUploading changes
    return () => {
      setAnimationComplete(false);
    };
  }, [isUploading]);

  const statusMessageStyle = {
    fontSize: "1rem",
    margin: "20px auto",
    textAlign: "center",
  };

  // New function to handle manual upload trigger
  const handleManualUpload = () => {
    setFormatChoice("PDF"); // Change the format choice to display the FileUploadComponent
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ textAlign: "left", width: "100%" }}>
        <div style={{ ...sharedFadeStyle }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h1
              style={{
                fontWeight: 600,
                fontSize: "1.9rem",
                marginRight: "10px",
              }}
            >
              Answer Key Generation
            </h1>
            <div style={{ position: "relative" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="gray"
                className="bi bi-info-circle"
                viewBox="0 0 16 16"
                style={{
                  transform: "translateY(-2px)",
                  transition: "transform 0.2s ease-in-out",
                  cursor: "pointer",
                }}
                onMouseEnter={() => setShowInfoTooltip(true)}
                onMouseLeave={() => setShowInfoTooltip(false)}
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                <path d="m8.93 6.588-2.29.287-.082.380.450.083c.294.070.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.200.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
              </svg>
              <div style={tooltipStyle}>
                <p style={{ fontWeight: "400", fontSize: "1rem" }}>
                  Upload a blank assignment for Osmos to generate an answer key,
                  this will be used down the line in grading.
                </p>
              </div>
            </div>
          </div>
          <h1
            style={{
              fontWeight: 400,
              fontSize: "1.6rem",
              color: "rgb(180, 180, 180)",
              ...sharedFadeStyle,
            }}
          >
            {subheadingText}
          </h1>
        </div>

        {!isUploading &&
          !isRequestComplete &&
          !showReviewScreen &&
          !formatChoice && (
            <ChooseFormatComponent onFormatChosen={handleFormatChoice} />
          )}
        {!isUploading &&
          !isRequestComplete &&
          !showReviewScreen &&
          formatChoice === "Image" && (
            <AssignmentScanner
              url={scannerUrl}
              onScanFinished={handleScanFinished}
              onManualUpload={handleManualUpload} // Pass the handleManualUpload function as a prop
            />
          )}

        {!isUploading &&
          !isRequestComplete &&
          !showReviewScreen &&
          formatChoice === "PDF" && (
            <FileUploadComponent
              imageFiles={imageFiles}
              onFilesChange={setImageFiles}
              onUploadClick={handlePDFUpload}
            />
          )}

        {isUploading && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="loader"></div>
            <div style={{ marginLeft: "10px" }}>
              {" "}
              {/* This div wraps the text */}
              <p style={statusMessageStyle}>{statusMessage}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default GeneratingPage;
