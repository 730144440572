import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Header from "../components/Header";
import TeacherClasses from "../components/TeacherClassCard";

function TeacherHomePage() {
  const navigate = useNavigate();

  useEffect(() => {
    const token = Cookies.get("token");
    if (!token || !token.startsWith("T")) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <div
      style={{
        backgroundColor: "rgb(243, 244, 246)",
        height: "100vh",
        overflow: "auto",
      }}
    >
      <Header />
      <div className="container">
        <h1
          style={{
            fontWeight: 700,
            fontSize: "5vw",
            textAlign: "left",
            marginTop: "5vw",
          }}
        >
          Welcome to Osmos
        </h1>
        <p
          style={{
            fontWeight: 400,
            fontSize: "2vw",
            textAlign: "left",
            marginBottom: "5vw",
            marginTop: "1vw",
          }}
        >
          No More Late Nights Spent Grading!
        </p>
        <TeacherClasses />
      </div>
    </div>
  );
}

export default TeacherHomePage;
