import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import InputPage from "./form_components/InputPage";
import SelectionPage from "./form_components/SelectionPage"; // Import the new component
import GeneratingPage from "./form_components/GeneratingPage"; // Import the new component
import QuestionWeightsPage from "./form_components/QuestionWeightsPage";
import InstructionsCard from "./onboarding/InstructionsCard"; // Adjust the path as necessary
import AutoGenerateSelector from "./form_components/AutoGenerateSelector"; // Import the new component
import { useError } from "../../ErrorContext";

function CreateAiGradeAssignment({ onClose, classId, onNewAssignmentCreated }) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [selection, setSelection] = useState("");
  const [isVisible, setIsVisible] = useState(true);
  const [currentStep, setCurrentStep] = useState("instructions");
  const [userInput, setUserInput] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [questionAndAnswers, setQuestionAndAnswers] = useState([]);
  const [autoGenerateSelection, setAutoGenerateSelection] = useState("");
  const { handleError } = useError();

  const steps = {
    "Step 1: Input the assignment name and description":
      "Get creative and whip up a title for your assignment, followed by a snappy description that encapsulates its purpose.",
    "Step 2: Provide context for Osmos":
      "Our algorithms have general knowledge of the world, but if the assignment is about something specific, such as a YouTube video or article, provide it here!",
    "Step 3: Answer Key Generation":
      "Osmos will automatically create an answer key from a blank assignment you provide, or you can upload an existing answer key.",
    "Step 4: Review the answer key":
      "Modify the questions and answers, weigh the point value of each question, and optionally give Osmos instructions on how to grade a question.",
  };

  const handleReviewScreenClear = async (data) => {
    // Save the rubric data to state
    console.log(data);
    setQuestionAndAnswers(data);
    setCurrentStep("review"); // Transition to the questionWeights step
    console.log("handleReviewScreenClear called");
  };

  const fadeOut = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    // Check for the autoGradeOnboarding cookie
    const autoGradeOnboarding = Cookies.get("autoGradeOnboarding");
    if (autoGradeOnboarding === "true") {
      setCurrentStep("name"); // Skip the instructions step if cookie is set
    }
  }, []); // Empty dependency array to run only on component mount

  const handleGenerationComplete = (data) => {
    // ... any additional logic ...
  };

  useEffect(() => {
    let timeoutId;
    if (!isVisible && currentStep !== "selection") {
      // Only set the timeout if we are not at the final 'selection' step.
      timeoutId = setTimeout(() => {
        setIsVisible(true); // Fade back in for the next input
      }, 500);
    }
    return () => clearTimeout(timeoutId); // Clear the timeout if the component unmounts
  }, [isVisible, currentStep]);

  const handleOkClick = () => {
    if (currentStep === "input") {
      fadeOut();
      setCurrentStep("generating");
      setIsGenerating(true); // Set generating to true
    } else {
      fadeOut();
    }
  };

  // Add a new useEffect or modify an existing one to handle the transition based on autoGenerateSelection
  useEffect(() => {
    if (autoGenerateSelection === "autoGenerate") {
      setCurrentStep("selection");
    } else if (autoGenerateSelection === "uploadExisting") {
      // Logic to open a file upload dialog and handle file upload goes here
    }
  }, [autoGenerateSelection]);

  // useEffect for handling the fade-out transition and then updating the step
  useEffect(() => {
    let timeoutId;
    if (!isVisible) {
      timeoutId = setTimeout(() => {
        // Update the step only after the fade-out is complete
        if (currentStep === "name") {
          setCurrentStep("description");
        } else if (currentStep === "description") {
          setCurrentStep("autoGenerateSelector");
        } else if (currentStep === "selection") {
          // Additional logic for the 'selection' step can go here
        }
        setIsVisible(true); // Begin to fade back in with new content
      }, 500); // This timeout should match the duration of your fade effect
    }
    // Clean up the timeout if the component unmounts or if isVisible changes
    return () => clearTimeout(timeoutId);
  }, [isVisible, currentStep]);

  // The handleSelection function needs to be modified to set the selection and allow for a re-render
  const handleSelection = (choice) => {
    setSelection(choice); // Set the user's choice
    if (choice === "Skip") {
      fadeOut(); // Start fade out for other selections
      setUserInput("N/A"); // Automatically set userInput to "N/A" if 'Skip' is selected
      setIsGenerating(true); // Proceed to generating step if 'Skip' is selected
    } else {
      fadeOut(); // Start fade out for other selections
    }
    // Change the current step after a slight delay to allow for the fade-out transition
    setTimeout(() => {
      setCurrentStep(choice === "Skip" ? "generating" : "input");
    }, 500); // This delay should match the fade-out transition duration
  };

  // Use a separate useEffect to handle the transition after a selection is made
  useEffect(() => {
    if (currentStep === "selection" && !isVisible) {
      // Wait for the fade-out to complete before moving to the next input field
      const timeoutId = setTimeout(() => {
        setCurrentStep("input"); // Now we're ready to input the link/text
        setIsVisible(true); // Fade back in for the next input
      }, 500); // Match this timeout to the fade transition time

      return () => clearTimeout(timeoutId);
    }
  }, [isVisible, currentStep, selection]);

  // Use a separate useEffect for handling the transition to generating step
  useEffect(() => {
    if (!isVisible && isGenerating) {
      setTimeout(() => {
        setCurrentStep("generating"); // Transition to the generating step
        setIsVisible(true); // Begin to fade back in with new content
      }, 500); // Match this timeout to the fade transition time
    }
  }, [isVisible, isGenerating]);

  const handleWeightsComplete = async (data) => {
    // Define the endpoint URL where the data should be sent
    const endpoint = `${process.env.REACT_APP_API_URL}/create`;
    console.log("handleWeightsComplete called");

    const token = Cookies.get("token");

    // Prepare the payload based on the received data and your fixed structure
    const payload = {
      class_id: classId, // Assuming classId is the ID of the current class, which is a prop to this component
      title: name, // The name state holds the assignment title
      description: description, // The description state holds the assignment description
      key: data.map(question => ({
        question: question.question,
        answer: question.answer,
        points: Number(question.points),
        instructions: question.gradingInstructions || ''
      }))
    };

    // Make the HTTP request to the endpoint with the prepared payload
    try {
      const response = await axios.post(endpoint, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          // Include any additional headers like authorization if needed
        },
      });
      console.log("Submission successful:", response.data);

      // Call onNewAssignmentCreated only if submission is successful
      onNewAssignmentCreated();

      // Proceed with any follow-up actions after successful submission
      setCurrentStep("submit");
    } catch (error) {
      console.error("Error submitting the data:", error);
      // Check if the error response is from our backend and matches the specific condition
      if (
        error.response &&
        error.response.data &&
        error.response.data.error === "Cannot have more than 15 questions"
      ) {
        handleError(
          "An assignment can have no more than 15 questions. Delete some questions then try again."
        );
      } else {
        // For any other errors, use a generic message or pass the error message
        handleError(`Error submitting the data: ${error}`);
      }
    }
    console.log("Weights data received:", data);
    setQuestionAndAnswers(data); // Assuming you want to update the state with the new data
  };

  // Function to handle the selection from AutoGenerateSelector
  const handleAutoGenerateSelection = (option, data = []) => {
    if (option === "autoGenerate") {
      setCurrentStep("selection"); // Change to the next step based on your application's flow
    } else if (option === "uploadExisting" && data.length > 0) {
      setQuestionAndAnswers(data); // Set the data received from the callback
      setCurrentStep("review"); // Move to the review step
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100%",
      }}
    >
      <button
        onClick={onClose}
        style={{
          position: "absolute",
          top: "20px",
          right: "15px",
          backgroundColor: "red",
          color: "white",
          border: "none",
          borderRadius: "5px",
          padding: "5px 10px",
          cursor: "pointer",
          zIndex: "9999", // Set the z index to be above everything else
        }}
      >
        Close
      </button>
      <div className="w-full max-w-2xl overflow-auto">
        {currentStep === "instructions" && (
          <InstructionsCard
            onDone={() => setCurrentStep("name")}
            steps={steps}
          />
        )}
        {currentStep === "name" && (
          <InputPage
            title="Name of the Assignment?"
            placeholder="Enter name..."
            isVisible={isVisible} // Add this line to pass isVisible prop
            onInputSubmit={(input) => {
              setName(input);
              handleOkClick();
            }}
          />
        )}

        {currentStep === "description" && (
          <InputPage
            title="Description of the Assignment?"
            placeholder="Optional description..."
            isVisible={isVisible}
            onInputSubmit={(input) => {
              setDescription(input);
              handleOkClick();
            }}
          />
        )}

        {currentStep === "autoGenerateSelector" && (
          <AutoGenerateSelector
            onOptionSelected={(option) => {
              if (option === "autoGenerate") {
                handleAutoGenerateSelection("autoGenerate");
              }
            }}
            onDataReceived={(data) => {
              handleAutoGenerateSelection(
                "uploadExisting",
                data.questionAnswers
              );
            }}
          />
        )}

        {currentStep === "selection" && (
          <SelectionPage
            title="Provide Context for Osmos"
            subheading="Our algorithms have general knowledge of the world, but if students need to have read or watched anything in particular. Provide it here!"
            options={["YouTube", "Article", "Textbook", "Skip"]}
            isVisible={isVisible}
            onSelection={(choice) => {
              setSelection(choice);
              handleSelection(choice);
            }}
          />
        )}
        {currentStep === "input" && (
          <InputPage
            title={
              selection === "YouTube"
                ? "Enter your YouTube link:"
                : selection === "Textbook"
                ? "Upload Textbook Pages:"
                : "Enter your article text:"
            }
            subheading={
              selection === "YouTube"
                ? "Copy and paste the YouTube link here:"
                : selection === "Textbook"
                ? "Select Images, or a PDF of The Textbook Pages:"
                : selection === "Article"
                ? "Copy and paste the article text here:"
                : "" // Adjust or keep this fallback as necessary
            }
            placeholder={
              selection === "YouTube"
                ? "YouTube link..."
                : selection === "Textbook"
                ? "Textbook reference (ISBN, Title, Author)..."
                : "Article text..."
            }
            onInputSubmit={(input) => {
              setUserInput(input);
              handleOkClick();
            }}
            isVisible={isVisible}
          />
        )}

        {currentStep === "generating" && questionAndAnswers.length === 0 && (
          <GeneratingPage
            isVisible={isVisible}
            userInput={userInput}
            onGenerationComplete={(data) => handleGenerationComplete(data)}
            onReviewScreenClear={handleReviewScreenClear}
          />
        )}

        {currentStep === "review" && (
          <QuestionWeightsPage
            questionAndAnswers={questionAndAnswers}
            isVisible={isVisible}
            onWeightsSubmit={handleWeightsComplete} // Updated to use the new handler
          />
        )}
      </div>
    </div>
  );
}

export default CreateAiGradeAssignment;
