import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Header from '../components/Header';
import TeacherAssignmentCard from '../components/TeacherAssignmentCard';
import ClassTitleCard from '../components/ClassTitleCard';
import CreateAssignmentCard from '../components/CreateAssignmentCard'; // Import the new component

function TeacherClassPage() {
  const [newAssignmentFlag, setNewAssignmentFlag] = useState(false); // New state variable
  const [refreshAssignments, setRefreshAssignments] = useState(false);
  const navigate = useNavigate();
  const { classId } = useParams();

  useEffect(() => {
    const token = Cookies.get('token');
    if (!token || !token.startsWith('T')) {
      navigate('/login');
    }
  }, [navigate]);

  const handleNewAssignmentCreated = () => {
    setRefreshAssignments(true); // Set flag to true to indicate assignments need refreshing
  };

  // Reset the refresh flag after it's been used
  useEffect(() => {
    if (refreshAssignments) {
      setRefreshAssignments(false);
    }
  }, [refreshAssignments]);

  return (
    <div>
      <Header />
      <ClassTitleCard classId={classId} />
      <div className='assignment-container'>
        <TeacherAssignmentCard
          classId={classId}
          newAssignmentFlag={newAssignmentFlag}
          refreshAssignments={refreshAssignments}
        />
      </div>
      <CreateAssignmentCard classId={classId} setNewAssignmentFlag={setNewAssignmentFlag} onAssingmentCreated={handleNewAssignmentCreated}/>
    </div>
  );
}

export default TeacherClassPage;
