import React from "react";

function SelectionPage({ title, subheading, options, onSelection, isVisible }) {
  const sharedFadeStyle = {
    transition: "opacity 0.5s ease",
    opacity: isVisible ? "1" : "0",
  };

  const buttonStyle = {
    backgroundColor: "#f0f0f0",
    color: "black",
    border: "1px solid #d0d0d0",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "1rem",
    width: "140px",
    height: "35px",
    textAlign: "center",
    ...sharedFadeStyle,
  };

  return (
    <div style={{ margin: "0 auto" }}>
      <h1 style={{ fontWeight: 600, fontSize: "1.9rem", ...sharedFadeStyle }}>
        {title}
      </h1>
      <h1
        style={{
          fontWeight: 400,
          fontSize: "1.6rem",
          color: "rgb(180, 180, 180)",
          marginTop: "5px",
          marginBottom: "20px",
          ...sharedFadeStyle,
        }}
      >
        {subheading}
      </h1>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start", // Align items to the left
          gap: "20px", // 20px gap between each button
          flexWrap: "wrap", // Allow items to wrap into multiple lines if needed
          marginTop: "10px",
          marginBottom: "5%",
          ...sharedFadeStyle,
        }}
      >
        {options.map((option, index) => (
          <button
            key={index}
            style={buttonStyle}
            onClick={() => onSelection(option)}
          >
            {option}
          </button>
        ))}
      </div>
    </div>
  );
}

export default SelectionPage;
