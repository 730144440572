import React, { useState, useEffect } from "react";
import Cookies from "js-cookie"; // Import js-cookie

function InstructionsCard({ onDone, steps }) {
  const stepKeys = Object.keys(steps);
  const totalSteps = stepKeys.length + 1;
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const initialProgress = (1 / totalSteps) * 100;
  const [progress, setProgress] = useState(initialProgress);

  useEffect(() => {
    setProgress(((currentStepIndex + 1) / totalSteps) * 100);
  }, [currentStepIndex, totalSteps]);

  const handleDontShowAgain = () => {
    // Set the cookie to indicate that the user does not want to see the onboarding again
    Cookies.set("autoGradeOnboarding", "true", { expires: 365 }); // Expires in 365 days
    console.log("Don't Show Again clicked");
  };

  const handleNext = () => {
    // Check if we are on the last step
    if (currentStepIndex < stepKeys.length - 1) {
      // Not the last step, proceed to the next step
      setCurrentStepIndex(currentStepIndex + 1);
    } else {
      // Manually set progress to 100% for visual completion before calling onDone
      setProgress(100);

      // Use a timeout to allow UI to update the progress bar to 100%
      setTimeout(() => {
        onDone();
      }, 700); // Adjust the delay here to match the duration of your progress bar transition
    }
  };

  const handleSkip = () => {
    onDone();
  };

  const progressBarColor = "bg-green-500";

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg w-full max-w-2xl p-6 space-y-6">
        <div className="w-full h-2 rounded-full bg-gray-200 dark:bg-gray-200 overflow-hidden">
          <div
            className={`h-full ${progressBarColor} rounded-full transition-all duration-500 ease-in-out`}
            style={{ width: `${progress}%` }}
          ></div>
        </div>
        <div className="space-y-4">
          <h2 className="text-2xl font-bold">{stepKeys[currentStepIndex]}</h2>
          <p className="text-gray-500 dark:text-gray-400">
            {steps[stepKeys[currentStepIndex]]}
          </p>
        </div>
        <div className="flex justify-between items-center">
          <div className="flex gap-3">
            <button
              className="inline-flex items-center justify-center rounded-md text-sm font-medium border border-input bg-background hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2"
              onClick={handleSkip}
            >
              Skip
            </button>
            <button
              className="inline-flex items-center justify-center rounded-md text-sm font-medium border border-input bg-background hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2"
              onClick={handleDontShowAgain}
            >
              Don't Show Again
            </button>
          </div>
          <button
            className="inline-flex items-center justify-center rounded-md text-sm font-medium bg-black text-white hover:bg-gray-900 h-10 px-4 py-2"
            onClick={handleNext}
            style={{ fontSize: "1rem" }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default InstructionsCard;
