import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import React, { useState, useEffect } from "react";
import logo from "../osmos-logo.png";
import "../index.css";
import axios from "axios";
import AccountOverlay from "./sub_components/header/AccountOverlay";
import HelpCard from "./sub_components/HelpCard";
import { useError } from "../ErrorContext"; // Import useError hook

function Header() {
  const navigate = useNavigate();
  const [showOverlay, setShowOverlay] = useState(false);
  const [buttonLabel, setButtonLabel] = useState("Close"); // Add this line
  const [showHelpOverlay, setShowHelpOverlay] = useState(false); // State for Help Overlay
  const [firstName, setFirstName] = useState(""); // Updated for first name
  const [lastName, setLastName] = useState(""); // Added for last name
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { handleError } = useError(); // Destructure handleError from the context

  const handleLogout = () => {
    Cookies.remove("token");
    navigate("/login");
  };

  const handleManageAccountClick = () => {
    setShowOverlay(!showOverlay);
    if (!showOverlay) {
      // Call fetchUserInfo only when the overlay is being opened
      fetchUserInfo();
    }
  };

  const toggleHelpOverlay = () => {
    setShowHelpOverlay(!showHelpOverlay); // Toggle visibility of Help Overlay
  };

  const handleSave = async () => {
    // Input validation for first name, last name, and email
    if (!firstName || !lastName || !email) {
      alert(
        "Please fill in the first name, last name, and email fields before saving."
      );
      return;
    }

    const isConfirmed = window.confirm(
      "Are you sure you want to save these changes?"
    );
    if (!isConfirmed) {
      return;
    }

    try {
      const token = Cookies.get("token");
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/update_user_info`,
        {
          first: firstName,
          last: lastName,
          email,
          password: password || null,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      if (response.status === 200) {
        console.log("User info updated successfully.");
        alert("User info updated successfully.");
        setShowOverlay(false);
      } else {
        alert("Failed to update user info.");
        handleError("Failed to update user info");
      }
    } catch (error) {
      console.error("Error updating user info:", error);
      handleError(error.message);
    }
  };

  const fetchUserInfo = async () => {
    try {
      const token = Cookies.get("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/get_user_info`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      if (response.status === 200) {
        setFirstName(response.data.first); // Set first name
        setLastName(response.data.last); // Set last name
        setEmail(response.data.email);
      }
    } catch (error) {
      console.error("Error fetching user info:", error);
      handleError("Failed to fetch user info");
    }
  };

  return (
    <div
      className="flex items-center"
      style={{
        borderBottom: ".5px solid gray",
        paddingTop: "15px",
        paddingBottom: "15px",
      }}
    >
      <Link to="/dashboard">
        <img
          src={logo}
          alt="Logo"
          style={{ width: "180px", marginLeft: "20px" }}
        />
      </Link>
      <div className="flex grow justify-end items-center">
        {" "}
        {/* Ensure vertical centering */}
        <a
          className="mx-3"
          href="#"
          onClick={toggleHelpOverlay}
          style={{ color: "gray", textDecoration: "none", fontWeight: "500" }}
        >
          Need Help?
        </a>
        <a
          className="mx-3"
          href="#"
          onClick={handleManageAccountClick}
          style={{ color: "gray", textDecoration: "none", fontWeight: "500" }}
        >
          Account Settings
        </a>
        <button
          className="btn rounded-pill border btn-hover-grow mx-3"
          style={{
            color: "white",
            backgroundColor: "#007bff",
            marginRight: "20px",
          }}
          onClick={handleLogout}
        >
          Logout
        </button>
      </div>
      {showOverlay && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex justify-center items-center">
          <AccountOverlay
            showOverlay={showOverlay}
            setShowOverlay={setShowOverlay}
            handleSave={handleSave}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
          />
        </div>
      )}
      {showHelpOverlay && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex justify-center items-center">
          <HelpCard closeOverlay={toggleHelpOverlay} />
        </div>
      )}
    </div>
  );
}

export default Header;
