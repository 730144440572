import React from "react";

export default function ScannerOrUpload({ onFormatChosen }) {
  const handleChoice = (choice) => {
    onFormatChosen(choice);
  };

  const buttonStyle = {
    padding: "20px",
    borderRadius: "10px",
    border: "0.5px solid gray",
    backgroundColor: "white",
    cursor: "pointer",
    fontSize: "16px",
    margin: "10px",
    minWidth: "150px",
    transition: "background-color 0.3s, color 0.3s",
  };

  const pdfSectionStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingRight: "50px", // Padding only on the right
    borderRight: "2px solid #d1d5db",
  };

  const imageSectionStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "50px", // Padding only on the left
  };

  // Updated style for the outer div
  const outerDivStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white", // Set background color to white
    borderRadius: "10px", // Set border radius to 10px
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)", // Optional: added shadow for better visual separation
    overflow: "hidden", // Ensure the children's content or borders do not overflow the rounded corners
    padding: "30px",
    marginBottom: "100px",
  };

  return (
    <div className="row" style={outerDivStyle}>
      <div className="col justify-center" style={pdfSectionStyle}>
        <div>
          <h2 className="text-3xl font-bold mb-4">Upload Existing Files</h2>
        </div>
        <div>
          <p
            className="text-gray-500 dark:text-gray-400 mb-8"
            style={{ textAlign: "center" }}
          >
            Upload existing images or PDF files, from your device
          </p>
        </div>
        <div>
          <button style={buttonStyle} onClick={() => handleChoice("PDF")}>
            Upload Files
          </button>
        </div>
      </div>
      <div className="col justify-center" style={imageSectionStyle}>
        <div>
          <h2 className="text-3xl font-bold mb-4">Open Scanner</h2>
        </div>
        <div>
          <p
            className="text-gray-500 dark:text-gray-400 mb-8"
            style={{ textAlign: "center" }}
          >
            Upload your image files using Osmos Scanner.
          </p>
        </div>
        <div>
          <button style={buttonStyle} onClick={() => handleChoice("Image")}>
            Open Scanner
          </button>
        </div>
      </div>
    </div>
  );
}
