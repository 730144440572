import React, { useState, useEffect } from "react";

const AlertComponent = ({ type, message }) => {
  const [isVisible, setIsVisible] = useState(false); // Initially hidden to trigger fade in

  useEffect(() => {
    // Trigger fade in immediately
    setIsVisible(true);

    // Set a timer to fade out after 5 seconds
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 5000);

    // Cleanup timer if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setIsVisible(false);
  };

  const typeStyles = {
    warning: "bg-yellow-100 border-yellow-400 text-yellow-700",
    error: "bg-red-100 border-red-400 text-red-700",
  };

  // Adjusted container styles for fixed height, and positioning
  const containerStyles = `fixed bottom-10 right-10 ${
    typeStyles[type]
  } border rounded transition-opacity duration-500 ease-in-out ${
    isVisible ? "opacity-100" : "opacity-0"
  }`;

  return (
    <div
      className={containerStyles}
      role="alert"
      style={{
        zIndex: 99999,
        padding: "0 20px",
        height: "60px", // Fixed height of 40px
        maxWidth: "calc(100% - 40px)", // Adjust maxWidth to account for padding
        display: "flex",
        alignItems: "center", // Vertically center the content
        justifyContent: "space-between", // Space between text and close icon
      }}
    >
      <div>
        {type === "error" && <strong className="font-bold">Error!</strong>}
        {type === "warning" && <strong className="font-bold">Warning!</strong>}
        <span className="block sm:inline ml-2">{message}</span>
      </div>
      <span onClick={handleClose} className="ml-4 cursor-pointer">
        <svg
          className="fill-current h-6 w-6 text-red-500"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <title>Close</title>
          <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
        </svg>
      </span>
    </div>
  );
};

export default AlertComponent;
