import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import ResetPasswordComponent from "../components/ResetPasswordComponent";

function ResetPasswordPage() {
  const { token } = useParams();
  return <ResetPasswordComponent resetToken={token} />;
}

export default ResetPasswordPage;
