import React from "react";

function AssignmentUploader({ imageFiles, handleFileChange, handleUpload }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <h2
        style={{
          textAlign: "center",
          fontSize: "3.5rem",
          fontWeight: "600",
        }}
      >
        Upload Completed Assignments
      </h2>
      <p
        style={{
          marginBottom: "10px",
          textAlign: "center",
          fontSize: "2rem",
          fontWeight: "600",
          color: "gray",
        }}
      >
        .PDF Files, Use Scanner For Images
      </p>
      <label
        htmlFor="fileInput"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#f0f0f0",
          padding: "15px",
          margin: "20px 0",
          height: "40vh",
          width: "80vh",
          border: "1px dashed #ccc",
          cursor: "pointer",
          color: "rgb(170, 170, 170)",
          fontWeight: "500",
          fontSize: "2.4rem",
        }}
      >
        {imageFiles.length > 0
          ? `${imageFiles.length} files selected`
          : "Select Files"}
      </label>
      <input
        id="fileInput"
        type="file"
        multiple
        onChange={handleFileChange}
        style={{ display: "none" }}
        accept=".pdf" // This line ensures only PDF files can be selected
      />
      <button
        onClick={handleUpload}
        style={{
          backgroundColor: "black",
          color: "white",
          border: "none",
          borderRadius: "5px",
          padding: "10px 20px",
          cursor: "pointer",
          marginTop: "20px",
          marginBottom: "50px",
          fontSize: "1.2rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "90px",
          height: "40px",
        }}
      >
        Upload
      </button>
    </div>
  );
}

export default AssignmentUploader;
